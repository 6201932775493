import React, {useState} from 'react';
import { IMainToolbarVM, MainToolbarVC } from '../../view-controllers/panels/MainToolbarVC';
import {MainToolbar} from '../panels/MainToolbar/MainToolbar';
import {lang} from '../../utils/utils';
import {AppConfig, AuthenticationService} from '@luxms/bi-core';
import {DsShellHeaderLogo} from './DsShellHeaderLogo';
import {Header} from '@luxms/bi-face';
import AccountTool from '@luxms/bi-face/AccountTool';
import ActionList from '../../../srx/components/action/ActionList';
import {BIIcon} from '../components/BIIcon/BIIcon';
import './DsShellHeader.scss';
import useService, { useServiceItself } from '../useService';
import {BreadcrumbControl} from '../Root/BreadcrumbControl';
const skin: any = require('../../skins/skin.json');


interface IDsShellHeaderProps {
  schema_name: string;
  dsTitle: string;
  dsDescription: string;
  dsUrl: string;
}

export const DsShellHeader = React.memo(({schema_name, dsTitle, dsDescription, dsUrl}: IDsShellHeaderProps) => {
  const authenticationService = useServiceItself<AuthenticationService>(AuthenticationService);
  const [accountMenuVisible, setAccountMenuVisible] = useState(false);
  const mainToolbar = useService<MainToolbarVC>(MainToolbarVC, schema_name);

  const onPressSignOut = async () => {
    await authenticationService.signOut();
    window.location.hash = '#';
  };

  const handleHide = () => setAccountMenuVisible(false);
  const toggleShow = () => setAccountMenuVisible(!accountMenuVisible);
  const handleClickAction = (key: string) => {
    if (key === 'logout') onPressSignOut();
    handleHide();
  };
  const toggleDatasetDescription = () => {
    // TODO
  };

  const title = skin.datasetLogoTitle || authenticationService.getModel()?.username ;

  const accountMenu = (
    <ActionList onClick={(key) => handleClickAction(key)} className="AccountList">
      {/*<ActionList.Action key="profile" className="AccountList__Item">{lang('profile')}</ActionList.Action>*/}
      <ActionList.Action key="logout" className="AccountList__Item">{lang('log_out')}</ActionList.Action>
    </ActionList>);

  return (
    <Header className="DsShellHeader" id="jsHeaderClassForCalcBodyHeight">
      {/*<Header.Title className="DsShellHeader__Logo">*/}
      {/*  <a className="DsShellHeader__LogoLink" href={skin.logoLink ? skin.logoLink.slice(1, -1) : '#/ds/'} >*/}
      {/*    <DsShellHeaderLogo schema_name={schemaName} title={title}/>*/}
      {/*  </a>*/}
      {/*  /!*<Router.Match path="/*" handler={ () => <BreadCrumbController breadcrumbs={props.appBreadcrumbs}/> }/>*!/*/}
      {/*</Header.Title>*/}


      <header className="DsShellTitle" data-bind="visible: (koDatasetTitle() != null)">
        <BreadcrumbControl/>
        {/*{!!dsUrl &&*/}
        {/*<a href={dsUrl} target="_blank">*/}
        {/*  <h1 style={{fontWeight: 'normal'}}>{dsTitle}</h1>*/}
        {/*</a>}*/}

        {/*{!dsUrl &&*/}
        {/*<h1 style={{fontWeight: 'normal'}} title={dsTitle}>{dsTitle}</h1>}*/}

        {!!dsDescription && dsDescription != dsTitle &&
        <BIIcon icon="info"
                onPress={toggleDatasetDescription}
                className="help-icon bi-icon light DatasetTitle__DescriptionIcon"/>}
      </header>

      <section className="DsShellHeader__Toolbar toolbar">
        {!mainToolbar.loading && !mainToolbar.error && <MainToolbar {...mainToolbar}/>}
      </section>

      <div className="AppHeader__Toolkit">
        <AccountTool visible={accountMenuVisible}
                     onClickOutside={handleHide}
                     onClick={toggleShow}
                     menu={accountMenu}
                     title={title}/>
      </div>
    </Header>);

    // return (
    //   <aside className="DsShellHeader">
    //     <header className="DsShellHeader__Logo logo">
    //       <a className="DsShellHeader__LogoLink" href={skin.logoLink ? skin.logoLink.slice(1, -1) : '#/ds/'} >
    //         <DsShellHeaderLogo schema_name={schemaName} title={title}/>
    //       </a>
    //     </header>
    //
    //     <section className="DsShellHeader__Toolbar toolbar">
    //       <MainToolbar {...this.props}/>
    //     </section>
    //
    //     <footer className="DsShellHeader__Logout btn-logout authorized" onClick={onPressSignOut}>
    //       <span className="DsShellHeader__LogoutIcon bi-icon light">
    //         <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
    //              x="0px" y="0px" width="22px" height="18px"
    //              viewBox="0 0 22 18" enableBackground="new 0 0 22 18">
    //           <path d="M2,15.215V2.785C2,2.353,2.353,2,2.785,2H12V0H2.785C1.249,0,0,1.249,0,2.785v12.43C0,16.751,1.249,18,2.785,18H12v-2 H2.785C2.353,16,2,15.647,2,15.215z"/>
    //           <polygon points="21.05,8.891 14.788,3.738 14.78,7 10,7 10,11 14.771,11 14.763,13.984"/>
    //         </svg>
    //       </span>
    //
    //       <span className="DsShellHeader__LogoutText text" >{lang('log_out')}</span>
    //     </footer>
    //   </aside>);
});
