import React from 'react';
import cn from 'classnames';
import {srv, AppConfig} from '@luxms/bi-core';
import AppLogo from '@luxms/bi-face/AppLogo';
import useService from '../../../../src/views/useService';
import './Logo.scss';
const skin = require('../../../../src/skins/skin.json');


const Logo = ({}) => {
  const appConfig = useService<AppConfig>(AppConfig);
  const dsRes = useService<srv.ds.ResourcesService>(srv.ds.ResourcesService, 'ds_res');

  if (dsRes.loading || dsRes.error) return null;

  let image = './assets/images/logo.png';
  let isFull = false;

  if (dsRes.find(r => r.alt_id === 'thumbnail.svg')) image = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnail.svg');
  else if (dsRes.find(r => r.alt_id === 'thumbnail.png')) image = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnail.png');
  else if (dsRes.find(r => r.alt_id === 'thumbnailFull.svg')) {
    image = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnailFull.svg');
    isFull = true;
  }

  const title = appConfig.projectTitle ?? skin?.appName ?? 'Luxms BI';

  return (
    <AppLogo icon={image} altImage={title} className={cn({isFull})}>
      {!isFull && <AppLogo.Text major>{title}</AppLogo.Text>}
    </AppLogo>);
};

export default Logo;
