import React from 'react';
import {IEntity, ISubspace, ISubspacePtr, IVCPV, IVizelController, IVizelProperties, tables} from '../../../defs/bi';
import Vizel from './Vizel';
import {data_engine} from '../../../data-manip/data-manip';
import {IVizelConfig} from '../../../services/ds/types';
import {createVizelConfig} from '../../../services/service';
import {createSubspaceGenerator} from '../../../services/ds/createSubspaceGenerator';
import {extractErrorMessage, IDisposable, repo, UrlState} from '@luxms/bi-core';
import isEqual from 'lodash/isEqual';
import $ from 'jquery';
import {parseVizelTypeString} from '../../../utils/utils';
import isObject from 'lodash/isObject';
import {
  ColorResolversChain,
  EntityConfigColorResolver,
  PaletteColorResolver,
  TextVizelColorResolver,
} from '../../../config/color-resolvers';
import VizelController from '../../../services/ds/VizelController';

export interface IVizelFromCfgProps {
  schema_name: string;
  view_class?: string;
  title?: string;
  description?: string;
  cfg?: any;
  rawCfg: tables.IRawVizelConfig;
  onVizelPropertiesChanged?: (properties: IVizelProperties, vizel: any) => void;
  properties?: IVizelProperties;
  datasetId?: number;
  dashId?: number | string;
  dashboardId?: number | string;
  dashboardTitle?: string;
  datasetTitle?: string;
  loadingIndicatorFunc?: () => void;
  renderError?: (error: string) => any;
  renderLoading?: (loading: boolean) => any;
  editMode?: string;
  onEditDashlet?: () => void;
  onChangeDashlets?: (updated: repo.ds.IRawDashlet[], deleted?: number[]) => any;
  dashlet?: any;
}


export class VizelFromCfg extends React.Component<IVizelFromCfgProps> {
  private _ref: Vizel | null = null;
  private _listener = null;
  private _properties = null;

  public state: {
    dp: data_engine.IDataProvider;
    cfg: IVizelConfig;
    subspace: ISubspace;
    loading: boolean;
    error: string;
    _vizelController: IVizelController;
  } = {
    dp: null,
    cfg: null,
    subspace: null,
    loading: true,
    error: '',
    _vizelController: null,
  };

  public constructor(props) {
    super(props);
  }

  private _cvcSubscription: IDisposable = null;

  public componentDidUpdate(prevProps: Readonly<IVizelFromCfgProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (!isEqual(prevProps.rawCfg, this.props.rawCfg) || prevProps.view_class !== this.props.view_class || prevProps.title !== this.props.title) {
      this._init();
    }
  }

  public componentDidMount(): void {
    this._init();
  }

  public componentWillUnmount() {
    if (this._cvcSubscription) {
      this._cvcSubscription.dispose();
      this._cvcSubscription = null;
    }
  }

  private _init() {
    if (this._cvcSubscription) {
      this._cvcSubscription.dispose();
      this._cvcSubscription = null;
    }

    const {rawCfg, schema_name, view_class, loadingIndicatorFunc} = this.props;
    if (!rawCfg) return;
    this.setState({loading: true, error: ''});
    createVizelConfig(rawCfg, schema_name, view_class).then(cfg => {
      const dp = cfg.getDataset().getDataProvider();
      const subspacePtr: ISubspacePtr = cfg.getSubspacePtr();
      const ds = cfg.getDataset();
      const isExternal: boolean = schema_name != ds.schema_name;
      const vizelController = this._getVizelController(cfg);
      this.setState({_vizelController: vizelController});
      cfg = this.genVizelConfig(cfg);

      if (!!cfg.getVizelType().match(/dashlet/)) subspacePtr.disableLoadData = 1; // !?! todo HACK, т.к. VizelDashlet грузит subspace

      this._cvcSubscription = createSubspaceGenerator(ds.schema_name, subspacePtr, isExternal, (subspace) => {
        vizelController.setAxes(subspace);
        this.setState({error: null, loading: false, dp, cfg, subspace});
        if (typeof loadingIndicatorFunc === 'function') {
          loadingIndicatorFunc();
        }
      });
    }).catch(err => {
      console.error(err);
      this.setState({error: extractErrorMessage(err), loading: false});
      if (typeof loadingIndicatorFunc === 'function') {
        loadingIndicatorFunc();
      }
    });
  }

  private _getVizelController(_vizelConfig): IVizelController {
    let {_vizelController} = this.state;
    if (!_vizelController) {
      //
      // TODO: make defaultAction: 'DoNothing' for each vizel
      //
      let defaultAction: string = 'ShowDrillDownMenu';
      switch (parseVizelTypeString(_vizelConfig.getVizelType()).type) {
        case 'text':
          defaultAction = 'SpreadOut';
          break;
        case 'circle':
        case 'semicircle':
        case 'list':
        case 'plan':
        case 'pixelmap':
        case 'table':
        case 'thermometer':
          defaultAction = 'DoNothing';
          break;
      }
      _vizelController = new VizelController(_vizelConfig, defaultAction);
    }
    return _vizelController;
  }

  private genVizelConfig(vizelConfig: IVizelConfig): IVizelConfig {
    const {
      datasetId, dashId, description, dashboardId, dashboardTitle, title,
      datasetTitle
    } = this.props;

    let externalUrl;
    const _vizelDataset = vizelConfig.getDataset();
    if (datasetId && datasetId !== _vizelDataset.id) {                        // external dash
      console.log('Found external dash');
      externalUrl = vizelConfig.getDataset().getEnterUrl();
    }
    const cfg: IVizelConfig = vizelConfig.clone();

    cfg.externalUrl = externalUrl;
    cfg.dashId = dashId;
    cfg.description = description;
    cfg.dashboardId = dashboardId ? dashboardId : '';

    const vizelType: string = cfg.getVizelType();

    cfg.title = title;
    // if (!(vizelType.indexOf('dashlet>') !== -1)) cfg.setVizelType('dashlet>' + vizelType);    // by default wrap vizel with VizelDashlet
    if (vizelType[0] === '/' || vizelType[0] === '>') {
      // special case: already wrapped
      cfg.setVizelType(vizelType.slice(1));
    }

    cfg.controller.handleChartClick = (event, subspace) => {
      const $el = $(event.target);
      if ($el.parents('.skip-vizel-click').length || $el.hasClass('skip-vizel-click')) {
        return;
      }

      const vizelController = this._getVizelController(vizelConfig);
      if (vizelController && vizelController.handleChartClick) {
        vizelController.handleChartClick(event, subspace);
      }
      this.setState({_vizelController: vizelController});
    };
    cfg.controller.handleVCPClick = (event, vcpv: IVCPV) => {
      const vizelController = this._getVizelController(vizelConfig);
      if (vizelController && vizelController.handleVCPClick) {
        vizelController.handleVCPClick(event, vcpv);
      }
      this.setState({_vizelController: vizelController});
    };
    cfg.controller.handleXClick = (event, e: IEntity) => {
      const vizelController = this._getVizelController(vizelConfig);
      if (vizelController && vizelController.handleXClick) {
        vizelController.handleXClick(event, e);
      }
      this.setState({_vizelController: vizelController});
    };
    cfg.controller.setChartClickAction = (action) => {                       // TODO: init here
      const vizelController = this._getVizelController(vizelConfig);
      if (vizelController && vizelController.setChartClickAction) {
        vizelController.setChartClickAction(action);
      }
      this.setState({_vizelController: vizelController});
    };
    cfg.controller.setChartClickAction((subspace: ISubspace) => {
      let evtDescription: any = vizelConfig.onClick;
      if (!evtDescription) {
        return;
      }
      //
      // TODO: add one common click handler
      //
      switch (String(evtDescription).toLowerCase()) {
        case 'opendataset':
          UrlState.navigate(cfg.externalUrl);
          return;
        case 'openlocationdataset':
          try {
            // UrlState.navigate(oneEntity(vizelSubspace.ls).config.onclick);
          } catch (err) {
            console.error(err);
          }
          return;
        case 'openparameterdataset':
        case 'openmetricdataset':
          try {
            // UrlState.navigate(oneEntity(vizelSubspace.ms).config.onclick);
          } catch (err) {
            console.error(err);
          }
          return;
      }
      if (isObject(evtDescription)) {
        if ('dataset' in evtDescription) {
          if (!('segmentId' in evtDescription)) evtDescription['segmentId'] = evtDescription['dataset'];
          if (!('segment' in evtDescription)) evtDescription['segment'] = 'ds';
          delete evtDescription['dataset'];
        }
        UrlState.navigate(evtDescription);
      }
    });

    cfg.controller.handleExpandFullScreen = () => {
      const {dashId} = this.props;
      const id = UrlState.getInstance().getModel().dash;
      UrlState.navigate({dash: dashId !== id ? dashId : null});
    };

    cfg.colorResolver = new ColorResolversChain(
      vizelConfig,
      vizelConfig.getStoplights(),
      new EntityConfigColorResolver(),
      new PaletteColorResolver());
    cfg.titleContext = [datasetTitle, dashboardTitle, title];

    if (vizelType === 'text' || vizelType === 'list' || vizelType === 'plan' || vizelType === 'pixelmap') {
      cfg.colorResolver = new ColorResolversChain(
        vizelConfig,
        vizelConfig.getStoplights(),
        new TextVizelColorResolver(vizelConfig));
    }

    return cfg;
  }

  public resize = (width, height) => {
    this._ref?.resize(width, height);
  }

  public save = (saveAbility, titleContext, $anchor) => {
    this._ref?.save(saveAbility, titleContext, $anchor);
  }

  public addListener = (listener) => {
    this._listener = listener;
    this._ref?.addListener(this._listener);
  }

  public setProperties = (o) => {
    this._properties = o;
    this._ref?.setProperties(o);
  }

  private _setupRef = (vizelRef: Vizel | null) => {
    this._ref = vizelRef;
    if (this._ref && this._listener) this._ref.addListener(this._listener);
    if (this._ref && this._properties) this._ref?.setProperties(this._properties);
  }

  static getDerivedStateFromError(err) {
    console.error(err);
    return { error: extractErrorMessage(err), loading: false };
  }

  public render() {
    const {onVizelPropertiesChanged, properties} = this.props;
    const {dp, cfg, subspace, loading, error} = this.state;

    if (error) return this.props.renderError ? this.props.renderError(error) :
      <div className="Vizel error">{error}</div>;

    if (loading) return this.props.renderLoading ? this.props.renderLoading(loading) : <div className="Vizel loading"/>;

    return (
      <Vizel ref={this._setupRef}
             {...this.props}
             dp={dp}
             cfg={cfg}
             subspace={subspace}
             onVizelPropertiesChanged={onVizelPropertiesChanged}
             properties={properties}
             renderError={this.props.renderError}
             renderLoading={this.props.renderLoading}/>);
  }
}

export default VizelFromCfg;
