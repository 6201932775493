/// <reference path="../defs/bi.d.ts"/>
/// <reference path="../services/norm.ts"/>

import isNumber from 'lodash/isNumber';
import { AppConfig, disposeAll, IDisposable } from '@luxms/bi-core';
import { IAxesOrder, IDatasetModel, IVizelConfig } from '../services/ds/types';
import { createNullCube } from '../data-manip/data-utils';
import { $eid, $eidx } from '../libs/imdas/list';
import formatNumberWithString from '@luxms/format-number-with-string';
import { sign } from './math';
import isObject from 'lodash/isObject';
import {
  IEntity,
  ILocation,
  IMetric,
  IMLP,
  IMLPPtrCube,
  IPeriod, IRawColor,
  ISubspace, IUnit, IValue,
  IVizelDescription,
  tables
} from '../defs/bi';
import { OptionsProvider } from '../config/OptionsProvider';
import COLOR_PALETTE from './color-palette';

const skin: any = require('../skins/skin.json');


// export global for frames
if (typeof window !== 'undefined') {
  (window as any).formatNumberWithString = formatNumberWithString;
}

declare var Luxms;

const METRIC_MIME_TYPE = 'application/vnd.luxmsbi.metric+json';
const LOCATION_MIME_TYPE = 'application/vnd.luxmsbi.location+json';
const PERIOD_MIME_TYPE = 'application/vnd.luxmsbi.period+json';
export const DASHLET_MIME_TYPE = 'application/vnd.luxmsbi.dashlet+json';
export const DIMENSION_ID_MIME_TYPE = 'application/vnd.luxmsbi.koob.dimension+string';
export const MEASURE_ID_MIME_TYPE = 'application/vnd.luxmsbi.koob.measure+string';
export const FILTERS_ID_MIME_TYPE = 'application/vnd.luxmsbi.koob.filters+string';
export const HIERARCHY_ID_MIME_TYPE = 'application/vnd.luxmsbi.koob.filters+string';

export function makeColor(c: IRawColor): string | null {
  if (c == null) return null;
  if (String(c).startsWith('lpe:')) return null;
  if (Array.isArray(c)) return 'rgb(' + (c as number[]).map(String).join(',') + ')';
  if (isNumber(c)) {
    const colorPallete: string[] = skin.colorPallete ?? COLOR_PALETTE;
    return colorPallete[(c as number) % colorPallete.length];
  }
  return (c as string);
}


export module vizel_config {
  export function getOption(cfg: tables.IRawVizelConfig, optionId: string, defaultValue?: boolean): boolean | undefined {
    const optionsProvider = new OptionsProvider(Array.isArray(cfg?.options) ? cfg.options : null);
    return optionsProvider.getOption(optionId, defaultValue);
  }
}


export module coloring {
  export interface IColor {
    toRGB(): RGBColor;

    toHSV(): HSVColor;

    add(d1: number, d2: number, d3: number): IColor;

    mul(x1: number, x2: number, x3: number): IColor;

    toString(): string;
  }

  export type IGradientColorStop = [number, string];

  interface IGradientColor {
    linearGradient: { x1: number; y1: number; x2: number; y2: number; };
    stops: IGradientColorStop[];
  }

  const formatHex2 = (v: number) => ('0' + Math.floor(v).toString(16)).substr(-2);

  export class RGBColor implements IColor {
    public constructor(public r, public g, public b) {
      this.r = Math.max(0, Math.min(this.r, 255));
      this.g = Math.max(0, Math.min(this.g, 255));
      this.b = Math.max(0, Math.min(this.b, 255));
    }

    public toRGB(): RGBColor {
      return this;
    }

    public toHSV(): HSVColor {
      const r = this.r / 255;
      const g = this.g / 255;
      const b = this.b / 255;

      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      var h, v = max;

      var d = max - min;
      var s = (max == 0) ? 0 : d / max;

      if (max === min) {
        h = 0;
      } else {
        switch (max) {
          case r:
            h = (g - b) / d + ((g < b) ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h = h / 6;
      }

      return new HSVColor(Math.floor(h * 360), Math.floor(s * 100), Math.floor(v * 100));
    }

    public add(d1: number, d2: number, d3: number): IColor {
      return new RGBColor(this.r + d1 * 255, this.g + d2 * 255, this.b + d3 * 255);
    }

    public mul(x1: number, x2: number, x3: number): IColor {
      return new RGBColor(this.r * x1, this.g * x2, this.b * x3);
    }

    public static interpolateRGB(c1: RGBColor, c2: RGBColor, k: number): RGBColor {       // k [0..1]
      return new RGBColor(
          (1 - k) * c1.r + k * c2.r,
          (1 - k) * c1.g + k * c2.g,
          (1 - k) * c1.b + k * c2.b);
    }

    public toString(): string {
      // return 'rgb(' + Math.floor(this.r) + ',' + Math.floor(this.g) + ',' + Math.floor(this.b) + ')';
      return '#' + formatHex2(this.r) + formatHex2(this.g) + formatHex2(this.b);
    }
  }

  export class HSVColor implements IColor {
    public constructor(public h: number, public s: number, public v: number) {
      this.h = Math.max(0, Math.min(this.h, 360));
      this.s = Math.max(0, Math.min(this.s, 100));
      this.v = Math.max(0, Math.min(this.v, 100));
    }

    public clone(): HSVColor {
      return new HSVColor(this.h, this.s, this.v);
    }

    /**
     * HSV to RGB color conversion
     *
     * H runs from 0 to 360 degrees
     * S and V run from 0 to 100
     *
     * Ported from the excellent java algorithm by Eugene Vishnevsky at:
     * http://www.cs.rit.edu/~ncs/color/t_convert.html
     */
    public toRGB(): RGBColor {
      var r, g, b;
      var i;
      var f, p, q, t;

      // Make sure our arguments stay in-range
      var h = Math.max(0, Math.min(360, this.h));
      var s = Math.max(0, Math.min(100, this.s));
      var v = Math.max(0, Math.min(100, this.v));

      // We accept saturation and value arguments from 0 to 100 because that's
      // how Photoshop represents those values. Internally, however, the
      // saturation and value are calculated from a range of 0 to 1. We make
      // That conversion here.
      s /= 100;
      v /= 100;

      if (s == 0) {
        // Achromatic (grey)
        r = g = b = v;
        return new RGBColor(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255));
      }

      h /= 60; // sector 0 to 5
      i = Math.floor(h);
      f = h - i; // factorial part of h
      p = v * (1 - s);
      q = v * (1 - s * f);
      t = v * (1 - s * (1 - f));

      switch (i) {
        case 0:
          r = v;
          g = t;
          b = p;
          break;
        case 1:
          r = q;
          g = v;
          b = p;
          break;
        case 2:
          r = p;
          g = v;
          b = t;
          break;
        case 3:
          r = p;
          g = q;
          b = v;
          break;
        case 4:
          r = t;
          g = p;
          b = v;
          break;
        default: // case 5:
          r = v;
          g = p;
          b = q;
      }

      return new RGBColor(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255));
    }

    public add(d1: number, d2: number, d3: number): IColor {
      return new HSVColor(this.h + d1 * 360, this.s + d2 * 100, this.v + d3 * 100);
    }

    public mul(x1: number, x2: number, x3: number): IColor {
      return new HSVColor(this.h * x1, this.s * x2, this.v * x3);
    }

    public mulEx(x1: number, x2: number, x3: number): IColor {
      if (this.s == 0 || this.s == 100) x2 = 1;
      if (this.v == 0 || this.v == 100) x3 = 1;
      return new HSVColor(this.h * x1, this.s * x2, this.v * x3);
    }

    public mulEx2(mh: number, ms: number, mv: number): IColor {
      if (this.s == 100 && this.v == 100) {
        return new HSVColor(this.h * mh, this.s * ms, this.v * mv);
      } else {
        if (this.s == 0 || this.s == 100) ms = 1;
        if (this.v == 0 || this.v == 100) mv = 1;
      }
      return new HSVColor(this.h * mh, this.s * ms, this.v * mv);
    }

    public toHSV(): HSVColor {
      return this;
    }

    public toString(): string {
      return this.toRGB().toString();
      // let hsl = hsv2hsl(this.h, this.s, this.v);
      // return 'hsl(' + hsl[0] + ',' + hsl[1] + '%,' + hsl[2] + '%)';
    }

    public static interpolateHSV(c1: HSVColor, c2: HSVColor, k: number): HSVColor {       // k [0..1]
      let h, h1 = c1.h, h2 = c2.h;
      if (Math.abs(h2 - h1) <= 180) {
        h = (1 - k) * h1 + k * h2;
      } else if (h2 < h1) {               //  [0]--(h2)----------(h1)---[360]
        h = ((1 - k) * h1 + k * (360 + h2)) % 360;
      } else {                            //  [0]--(h1)----------(h2)---[360]
        h = ((1 - k) * (h1 + 360) + k * h2) % 360;
      }
      return new HSVColor(
          h,
          (1 - k) * c1.s + k * c2.s,
          (1 - k) * c1.v + k * c2.v);
    }
  }

  const CSS_COLORS_BY_NAME: { [id: string]: string } = {
    black: '#000000',
    blue: '#0000ff',
    green: '#008000',
    red: '#ff0000',
    white: '#ffffff',
    yellow: '#ffff00',
  };

  export function make(s: string): IColor {
    try {
      if (s.match(/^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/)) {
        return new RGBColor(parseInt(RegExp.$1, 16), parseInt(RegExp.$2, 16), parseInt(RegExp.$3, 16));
      }
      if (s.match(/^#([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})$/)) {
        return new RGBColor(parseInt(RegExp.$1 + RegExp.$1, 16), parseInt(RegExp.$2 + RegExp.$2, 16), parseInt(RegExp.$3 + RegExp.$3, 16));
      }
      if (s.match(/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/)) {
        return new RGBColor(parseInt(RegExp.$1, 10), parseInt(RegExp.$2, 10), parseInt(RegExp.$3, 10));
      }
      if (s in CSS_COLORS_BY_NAME) {
        return make(CSS_COLORS_BY_NAME[s]);
      }
    } catch (err) {
      debugger;
    }
    throw new Error('Unknown color: ' + String(s));
  }

  export function makeGradient(gradientType: string, baseColor: string): IGradientColor {
    gradientType = String(gradientType).toLowerCase();
    const base: HSVColor = make(baseColor).toHSV();
    if (gradientType === '3d') {
      let stops: IGradientColorStop[];

      if (base.s === 100 && base.v === 100) {
        stops = [
          [0.00, base.add(0.0, 0.0, -0.1).toString()],
          [0.30, base.add(0.0, -0.3, 0.0).toString()],
          [0.70, base.toString()],
          [1.00, base.add(0, 0.0, -0.2).toString()],
        ];
      } else if (base.v === 100 || base.v === 0) {
        stops = [
          [0.00, base.toString()],
          [0.20, base.add(0.0, -0.2, 0.0).toString()],
          [0.40, base.toString()],
          [0.65, base.add(0, +0.2, 0.0).toString()],
          [0.90, base.toString()],
          [1.00, base.add(0, +0.1, 0.0).toString()],
        ];
      } else if (base.s === 100 || base.s === 0) {
        stops = [
          [0.00, base.toString()],
          [0.20, base.add(0.0, 0.0, +0.2).toString()],
          [0.40, base.toString()],
          [0.65, base.add(0, 0.0, -0.2).toString()],
          [0.90, base.toString()],
          [1.00, base.add(0, 0.0, -0.1).toString()],
        ];
      } else {
        stops = [
          [0.00, base.toString()],
          [0.20, base.add(0.0, -0.2, +0.2).toString()],
          [0.40, base.toString()],
          [0.65, base.add(0, +0.2, -0.2).toString()],
          [0.90, base.toString()],
          [1.00, base.add(0, +0.1, -0.1).toString()],
        ];
      }

      return {
        linearGradient: {x1: 0, y1: 0, x2: 1, y2: 0},
        stops: stops,
      };
    } else if (gradientType === 'opacity') {
      const {r, g, b} = make(baseColor).toRGB();
      return {
        linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
        stops: [
          [0, `rgba(${r}, ${g}, ${b}, 1)`],
          [0.2, `rgba(${r}, ${g}, ${b}, 0.3)`],
          [0.75, `rgba(${r}, ${g}, ${b}, 0.1)`],
          [1, `rgba(${r}, ${g}, ${b}, 0.1)`],
        ],
      };

    } else if (gradientType === 'transparentize') {
      const {r, g, b} = make(baseColor).toRGB();
      return `rgba(${r}, ${g}, ${b}, 0.7)` as any;

    } else {
      // default gradient
      const upper: IColor = base.mul(1, 0.7, 1.3);
      return {
        linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
        stops: [
          [0, base.toString()],
          [1, upper.toString()],
        ],
      };
    }
  }
}


// Helper function: make plain config from tree-based object
export function makePlainConfig(treeConfig: any, prefix: string = ''): any {
  let result: any = {};
  if (isObject(treeConfig)) {
    for (let key in treeConfig) {
      if (treeConfig.hasOwnProperty(key)) {
        result = {
          ...result,
          ...makePlainConfig(treeConfig[key], (prefix ? prefix + '.' : '') + key),
        };
      }
    }
  } else {
    result[prefix] = treeConfig;
  }
  return result;
}

export module bi {

  function getAxisProjectionName(es: IEntity[]) {
    if (IS_MS(es)) return 'metrics';
    else if (IS_LS(es)) return 'locations';
    else if (IS_PS(es)) return 'periods';
    else return '';
  }

  export function createSimpleSubspace(xs: IEntity[], ys: IEntity[], zs: IEntity[]): ISubspace {
    // warning: maybe must copy
    // xs = xs.slice(0);
    // ys = ys.slice(0);
    // zs = zs.slice(0);

    let axesOrder: IAxesOrder = [getAxisProjectionName(zs), getAxisProjectionName(ys), getAxisProjectionName(xs)];

    if (axesOrder.indexOf('') !== -1) {
      // fill empty places with non-existent
      for (let a of ['metrics', 'locations', 'periods']) {
        if (axesOrder.indexOf(a) === -1) {               // no such axis name
          const idx: number = axesOrder.indexOf('');
          if (idx !== -1) {
            axesOrder[idx] = a;
          }
        }
      }
    }

    const ms: IMetric[] = FIND_MS(zs, ys, xs) || [];
    const ls: ILocation[] = FIND_LS(zs, ys, xs) || [];
    const ps: IPeriod[] = FIND_PS(zs, ys, xs) || [];

    return {
      xAxis: axesOrder[2],
      yAxis: axesOrder[1],
      zAxis: axesOrder[0],
      ms, ls, ps,
      xs, ys, zs,
      axesOrder: makeAxesOrderFromArray(axesOrder),
      getZ: (idx: number): IEntity => zs[idx],
      getY: (idx: number): IEntity => ys[idx],
      getX: (idx: number): IEntity => xs[idx],

      getMLP: (z: IEntity, y: IEntity, x: IEntity): IMLP => {
        return {
          m: (IS_M(z) && z || IS_M(y) && y || IS_M(x) && x || null) as IMetric,
          l: (IS_L(z) && z || IS_L(y) && y || IS_L(x) && x || null) as ILocation,
          p: (IS_P(z) && z || IS_P(y) && y || IS_P(x) && x || null) as IPeriod,
        };
      },

      reduce: (nx: number, ny: number, nz: number): ISubspace => {
        const subspace: ISubspace = createSimpleSubspace(nEntities(xs, nx), nEntities(ys, ny), nEntities(zs, nz));
        return subspace;
      },
      isEmpty: (): boolean => !(zs.length && ys.length && xs.length),
      splitByX: (): ISubspace[] => xs.map((x: IEntity): ISubspace => createSimpleSubspace([x], ys, zs)),
      splitByY: (): ISubspace[] => ys.map((y: IEntity): ISubspace => createSimpleSubspace(xs, [y], zs)),
      splitByZ: (): ISubspace[] => zs.map((z: IEntity): ISubspace => createSimpleSubspace(xs, ys, [z])),
      getZYXIndexesByMLPIds: (mid: string, lid: string, pid: string): [number, number, number] => {
        let mi: number = $eidx(ms, mid);
        if (mi === -1) return [-1, -1, -1];

        let li = $eidx(ls, lid);
        if (li === -1) return [-1, -1, -1];

        let pi = $eidx(ps, pid);
        if (pi === -1) return [-1, -1, -1];

        return [zs === ms ? mi : (zs === ls ? li : (zs === ps ? pi : -1)), ys === ms ? mi : (ys === ls ? li : (ys === ps ? pi : -1)), xs === ms ? mi : (xs === ls ? li : (xs === ps ? pi : -1))];
      },

      projectData: function (mlpCube: IMLPPtrCube): IValue[][][] {
        let cube: IValue[][][] = createNullCube(this.zs.length, this.ys.length, this.xs.length);
        mlpCube.forEach((mid: string, lid: string, pid: string, v: IValue): void => {
          let [zi, yi, xi] = this.getZYXIndexesByMLPIds(mid, lid, pid);
          try {
            if (zi !== -1 && yi !== -1 && xi !== -1) {
              cube[zi][yi][xi] = v;
            }
          } catch (err) {
            debugger;
          }
        });
        return cube;
      },

      toString: (): string => {
        return 'Subspace[' + [String(zs.length), String(ys.length), String(xs.length)].join('x') + ']';
      },

      getArity: () => 3,

      getRawConfig: (): tables.IDataSource => {
        return {
          metrics: ms.map((m: IMetric) => m.id),
          locations: ls.map((l: ILocation) => l.id),
          periods: ps.length ? {
            start: ps[0].id,
            end: ps[ps.length - 1].id,
            type: ps[ps.length - 1].period_type,
          } : [], // periodType: ps.length ? ps[ps.length - 1].period_type : null,
          zAxis: axesOrder[0],
          yAxis: axesOrder[1],
          xAxis: axesOrder[2],        // style?: {metrics?: {[id: string]: ILegendItem}, locations?: {[id: string]: ILegendItem}, periods?: {[id: string]: ILegendItem}};
          // dataset: string;
        };
      },
      getXsLength: async () => xs.length,
      getYsLength: async () => ys.length,
      createSubspaceAxes: (x, y) => _createSubspaceAxes(x, y),
    };

    // для pivotP, лучше не придумал ='(
    function _createSubspaceAxes(x: [number, number], y: [number, number]): any {
      const xss = xs.slice(x[0], x[1]);
      const yss = ys.slice(y[0], y[1]);
      return {
        xs: xss,
        ys: yss,
        createSubspaceAxes: (x, y) => _createSubspaceAxes(x, y),
      };
    }
  }

  export function createSimpleSubspaceXYZ(xs: IEntity[], ys: IEntity[], zs: IEntity[]): ISubspace {
    return createSimpleSubspace(xs, ys, zs);
  }

  export function createSimpleSubspaceZYX(zs: IEntity[], ys: IEntity[], xs: IEntity[]): ISubspace {
    return createSimpleSubspace(xs, ys, zs);
  }
}

export function subscribeServices(services: any[], callback: any, immediateNotify: boolean = false): IDisposable {
  // get list of models
  const models: any[] = services.map(service => service.getModel());

  // run main callback with all models
  const notify = () => {
    try {
      callback.apply(this, models);
    } catch (err) {
      console.error(err);
    }
  };

  // create list of callbacks for each service
  let callbacks: any[] = services.map((service, idx) => (model) => {
    models[idx] = model;
    notify();               // on each model changes -> call notify
  });

  // subscribe on each service
  let subscriptions = services.map((service, idx) => service.subscribe('update', callbacks[idx]));

  if (immediateNotify) {
    notify();
  }

  return {
    dispose: () => {
      disposeAll(subscriptions);
      subscriptions = null;
    },
  };
}

export function subscribeServicesAndNotify(services: any[], callback: any): IDisposable {
  return subscribeServices(services, callback, true);
}

//
// is-mergeable-object
//
export const isMergeableObject: any = function isMergeableObject(value) {
  return isNonNullObject(value)
      && !isSpecial(value);
};

function isNonNullObject(value) {
  return !!value && typeof value === 'object';
}

function isSpecial(value) {
  var stringValue = Object.prototype.toString.call(value);

  return stringValue === '[object RegExp]'
      || stringValue === '[object Date]'
      || isReactElement(value);
}

// see https://github.com/facebook/react/blob/b5ac963fb791d1298e7f396236383bc955f916c1/src/isomorphic/classic/element/ReactElement.js#L21-L25
let canUseSymbol = typeof Symbol === 'function' && Symbol.for;
let REACT_ELEMENT_TYPE = canUseSymbol ? Symbol.for('react.element') : 0xeac7;

function isReactElement(value) {
  return value.$$typeof === REACT_ELEMENT_TYPE;
}

/**
 *
 * Simple resize watcher that works with iframes
 *
 */
interface IResizeWatcherItem {
  container: HTMLElement;
  rect: ClientRect;
  callback: (container: HTMLElement) => any;
}

function eqClientRect(a: ClientRect, b: ClientRect): boolean {
  if (a === b) return true;
  if (!a && !b) return true;
  if (!a || !b) return false;
  for (const key of ['bottom', 'height', 'left', 'right', 'top', 'width', 'x', 'y']) {
    if (a[key] != b[key]) {
      return false;
    }
  }
  return true;
}

let __resizeWatcherItems = [];

const __resizeWatcherFunc = () => {
  for (let item of __resizeWatcherItems) {
    const {container, rect, callback} = item;
    // TODO: check if container detached and remove if so
    const newRect: ClientRect = container.getBoundingClientRect();
    if (!eqClientRect(newRect, rect)) {
      item.rect = newRect;        // update rect
      try {
        callback(container);
      } catch (err) {
        console.error(err);
      }
    }
  }
};

const __resizeWatcherInterval: number = window.setInterval(__resizeWatcherFunc, 200);

export function addResizeWatcher(container: HTMLElement, callback: (container: HTMLElement) => any): IDisposable {
  const rect: ClientRect = container.getBoundingClientRect();
  let resizeWatcherItem: IResizeWatcherItem = {container, rect, callback};
  __resizeWatcherItems.push(resizeWatcherItem);
  return {
    dispose: () => {
      const idx: number = __resizeWatcherItems.indexOf(resizeWatcherItem);
      if (idx !== -1) {
        __resizeWatcherItems.splice(idx, 1);
        resizeWatcherItem = null;
      }
    },
  };
}

export function markContinuousPeriodType<E extends IEntity>(es: E[], cpt: [number, number] | null): E[] {
  if (!cpt) {
    return es;
  }
  Object.defineProperty(es, '__continuousPeriodType', {
    value: cpt,
    writable: false,
    enumerable: false,
    configurable: true,
  });
  return es;
}


/**
 * @param {T[]} es - массив сущностей
 * @param {number} n - кол-во извлекаемых э-ов
 * @return {T[]}
 * @description Извлекает из массива сущностей n-элементов, всегда с конца массива
 */
export function nEntities<T extends IEntity>(es: T[], n: number): T[] {
  if (!Array.isArray(es)) es = [];
  if (n === Infinity) return es;
  if (es.length === 0) return [];

  let start = es.length - n;
  if (start < 0) start = 0;

  const result = es.slice(start);

  markContinuousPeriodType(result, (es as any).__continuousPeriodType);

  return result;
}

/**
 * @param {T[]} es - массив сущностей
 * @return {T[]}
 * @description Извлекает из массива сущностей последний элемент массива, возвращает массив
 */
export function oneEntities<T extends IEntity>(es: T[]): T[] {
  return nEntities(es, 1);
}

/**
 * @param {T[]} es - массив сущностей
 * @return {T | null}
 * @description Извлекает из массива сущностей последний элемент массива
 */
export function oneEntity<T extends IEntity>(es: T[]): T {
  es = nEntities(es, 1);
  return es.length ? es[0] : null;
}

function getFileNameForVizelType(type: string): string {
  switch (type) {
    case 'board'            :
      return 'VizelBoard';
    case 'hcolumn'          :
    case 'hstacked-column'  :
    case 'hfixed-column'    :
      return 'plot';
    case 'column'           :
    case 'stacked-column'   :
    case 'estacked-column'   :
    case 'fixed-column'     :
      return 'ecolumn';
    case 'hcolumn1d'        :
      return 'column1d';
    case 'column1d'         :
      return 'ecolumn1d';
    case 'hline'            :
    case 'hscatter'         :
    case 'hspline'          :
      return 'plot';
    case 'eline'            :
    case 'line'             :
    case 'scatter'          :
    case 'spline'           :
      return 'eplot';
    case 'hbar'             :
    case 'hstacked-bar'     :
    case 'hfixed-bar'       :
      return 'bar';
    case 'hbar1d'           :
      return 'column1d';
    case 'ebar'              :
    case 'bar'              :
    case 'estacked-bar'      :
    case 'stacked-bar'      :
    case 'fixed-bar'        :
      return 'ebar';
    case 'bar1d'            :
      return 'ecolumn1d';
    case 'hclassified-bar'   :
    case 'hclassified-column':
    case 'hcompare-sort'     :
      return 'compare-sort';
    case 'classified-bar'    :
    case 'classified-column' :
    case 'compare-sort'      :
      return 'ecompare-sort';
    case 'harea'             :
    case 'hstacked-area'     :
      return 'area';
    case 'area'              :
    case 'stacked-area'      :
    case 'estacked-area'      :
      return 'earea';
    case 'hpie'              :
      return 'pie';
    case 'ebublik':
    case 'bublik':
      return 'ebublik';
    case 'pie'               :
    case 'epie'               :
      return 'epie';
    case 'hcircle'           :
      return 'circle';
    case 'halfgauge'             :
    case 'semigauge'             :
      return 'halfgauge';
    case 'circle'            :
    case 'circle1d'          :
    case 'egauge'            :
      return 'gauge';
    case 'hsemicircle'       :
      return 'semicircle';
    case 'esemicircle'       :
    case 'semicircle'        :
      return 'halfgauge';
    case 'hradar'            :
      return 'radar';
    case 'eradar'            :
    case 'radar'             :
      return 'eradar';
    case 'radar1d'          :
      return 'eradar1d';
    case 'hthermometer'     :
      return 'thermometer';
    case 'thermometer'     :
    case 'ethermometer'     :
      return 'ethermometer';
    case 'hfunnel'           :
    case 'hfunnel3d'         :
    case 'hpyramid'          :
    case 'hpyramid3d'        :
      return 'funnel';
    case 'funnel'            :
    case 'funnel3d'          :
    case 'pyramid'           :
    case 'pyramid3d'         :
      // return 'efunnel';
      return 'FunnelNew';
    case 'text'             :
    case 'value'            :
    case 'label'            :
      return 'VizelLabel';                                    // 'c-label'
    case 'planII'           :
      return 'plan';
    case 'table'            :
      return 'VizelTable';
    case 'table1d'          :
      return 'VizelTable1D';
    case 'dashlet'          :
      return 'VizelDashlet';
    case 'trendlet'         :
      return 'VizelTrendlet';
    case 'lcard'            :
      return 'VizelLCard';
    case 'lookup-table'     :
      return 'VizelLookupTable';
    case 'koob-line'        :
    case 'hkoob-line'       :
    case 'koob-area'        :
    case 'hkoob-area'       :
    case 'koob-bar'         :
    case 'hkoob-bar'        :
    case 'koob-column'      :
    case 'hkoob-column'     :
    case 'koob-table'       :
    case 'koob-accordeons'  :
      return 'VizelKoob';
    case 'koob-table-simple':
      return 'VizelKoobTableSimple2';
    case 'koob-table-simple2':  // todo заменить как будет готова
      return 'VizelKoobTableSimple';
    case 'waterfall1d'      :
    case 'waterfall'        :
    case 'waterfallbar1d'   :
      return 'ewaterfall';
    case 'hwaterfall'        :
      return 'waterfall1d';
    case 'scales'           :
      return 'VizelScales';
    case 'pivot'            :
      return 'VizelPivot';
    case 'tableP'           :
      return 'VizelTableP';
    case 'histogram'        :
      return 'VizelHistogram';
    case 'correlation'      :
      return 'VizelCorrelation';
    case 'correlationnew'      :
      return 'VizelCorrelationNew';
      // case 'bublik':
      //   return 'VizelBublik';
    case 'pp':
      return 'VizelPP';
    case 'three':
      return 'VizelThree';
    case 'three-column':
      return 'VizelThreeColumns';
    case 'editor':
      return 'VizelEditor';
    case 'whatif':
      return 'VizelWhatIf';
    case 'mapdots':
    case '@mapdots':
      return 'VizelMapPoints';
    case 'mapcharts':
      return 'VizelMapCharts';
    case 'mapareas':
    case '@mapareas':
      return 'VizelMapAreas';
    case 'map'                        :
      return 'VizelMap';
    case 'axes-selector'            :
      return 'VizelAxesSelector';
    case 'tabs':
      return 'VizelTabs';
    case 'treemap':
      return 'VizelTreemap';
  }
  return type;
}

/**
 * @param {string} viewClass - тип группы визеля
 * @param {string} chartStyle - тип визуализации
 * @return {string} - отдает тип визуализации в формате (типГруппы.типВизеля)
 * @description Принимает тип группы визезя, и тип визуализаци
 */
export function fixViewClass(viewClass: string, chartStyle: string): string {
  switch (viewClass) {
    case 'BISpeedMeterDashView':
      switch (chartStyle) {
        case 'Gauge':
          return '111.gauge';
        case 'Thermometer':
          return '111.thermometer';
        default:
          return '111.semicircle';
      }
    case 'BIPieChartDashView':
      return '1I1.pie';

    case 'BILabelDashView':
    case 'text':
      return (chartStyle === 'list') ? 'list' : 'text';
    case 'BIPlanDashView':
      return 'plan';
    case 'BIPixelmapDashView':
      return 'pixelmap';

    case 'BIChartDashView':
      switch (chartStyle) {
        case 'TimeLine'      :
          return '1II.line';
        case 'Column'        :
          return '1II.column';
        case 'StackedColumn' :
          return '1II.stacked-column';
        case 'Bar'           :
          return '1II.bar';
        case 'StackedBar'    :
          return '1II.stacked-bar';
        case 'Line'          :
          return '1II.line';
        case 'Area'          :
          return '1II.stacked-area';
        case 'Pie'           :
          return '1I1.pie';
        case 'Radar'         :
          return '1I1.radar';
        case 'Table'         :
          return '1II.table';
        default              :
          console.warn('Unknown dash chartStyle: ' + chartStyle);
          return '1II.line';
      }
    case 'LookupTable':
      return 'lookup-table';
    default:
      return viewClass;
  }
}

/**
 * @param {string} str - строка вида 'I1I.vizel>vizel' или 'vizel/vizel'
 * @description Парсит строку типа визеля на объект. Находит группу, тип визеля, внутренний визель ...
 */
export function parseVizelTypeString(str: string): IVizelDescription {
  let type: string = str || '';
  let group: string = null;
  let inner: string = null;

  if (type.match(/^(.+?)[/>](.+)$/)) {      // "outer/inner" | "outer>inner"
    type = RegExp.$1;
    inner = RegExp.$2;
  }

  if (type.match(/^(.+?)\.(.+)$/)) {      // "group.type"
    group = RegExp.$1;
    type = RegExp.$2;
  }

  // type = type.replace(/(?:^|[a-z])[A-Z]/g, function (x) {
  //   return x.split('').join('-').toLowerCase()
  // });    // CamesCase to lower-case

  let file: string = getFileNameForVizelType(type);

  let title = lang(`vizel.type.${type}`, type);
  let icon: string = type;

  return {
    type,
    group,
    inner,
    file,
    title,
    icon,
    toString: function vizelDescriptionToString(): string {
      let result = this.type || '';
      if (this.group) {
        result = this.group + '.' + result;
      }
      if (this.inner) {
        result = result + '>' + this.inner;
      }
      return result;
    },
  };
}


export function getSpreadoutVizelType(vizelTypeString: string): string {
  const vizelType: string = parseVizelTypeString(vizelTypeString).type;
  switch (vizelType) {
    case 'line':
    case 'scatter':
    case 'column':
    case 'stacked-column':
    case 'bar':
    case 'stacked-bar':
    case 'area':
    case 'stacked-area':
    case 'eline':
    case 'escatter':
    case 'ecolumn':
    case 'estacked-column':
    case 'ebar':
    case 'estacked-bar':
    case 'earea':
    case 'estacked-area':
      return 'table';

    case 'circle':
    case 'semicircle':
    case 'thermometer':
    case 'text':
    case 'scales':
      return 'line';
  }
  return null;
}

/**
 * @param {string} vizelGroup - строка типа группы визеля 1II,1I1,111...
 * @description Возвращает массив типов визеля входящий в группу визелей
 */
export function getGroupVizelTypes(vizelGroup: string): string[] {
  switch (vizelGroup) {
    case 'h1II':
      return ['hline', 'hstacked-area', 'hstacked-column', 'hcolumn', 'hbar', 'hstacked-bar', 'table', 'hscatter'];      // TODO: we lost scatter
    case 'h1I1':
      return ['hradar', 'hpie', 'hcolumn1d', 'table1d'];
    case 'h111':
      return ['hcircle', 'hsemicircle', 'hthermometer'];

    case 'e1II':
      return ['eline', 'estacked-area', 'estacked-column', 'ecolumn', 'ebar', 'estacked-bar', 'table', 'escatter'];      // TODO: we lost scatter
    case 'e1I1':
      return ['eradar', 'epie', 'ecolumn1d', 'table1d'];
    case 'e111':
      return ['egauge', 'esemicircle', 'ethermometer'];

    case '1II':
      return ['line', 'stacked-area', 'stacked-column', 'column', 'bar', 'stacked-bar', 'table', 'scatter'];      // TODO: we lost scatter
    case '1I1':
      return ['radar', 'pie', 'bublik', 'column1d', 'table1d'];
    case '111':
      return ['gauge', 'semicircle', 'thermometer'];
    case 'trends':
      return ['eline', 'scatter', 'column', 'stacked-column', 'area'];
  }
  if (vizelGroup && vizelGroup[0] === '[' && vizelGroup[vizelGroup.length - 1] === ']') {
    return vizelGroup.slice(1, vizelGroup.length - 1).split(',');
  }
  return [];
}


// deprecated
export const MessageHub = {
  send: function (name, sender?, params?) {
    // this.logCommand(name, sender, params);
    $(MessageHub).trigger(name, [sender, params]);
  },

  receive: function (name, callBack) {
    $(MessageHub).on(name, callBack);
  },

  off: function (name, callBack) {
    $(MessageHub).off(name, callBack);
  },
};
(window as any).MessageHub = MessageHub;


const __failed_localization_reported = {};

/**
 * @param {string} key - ключ для файла локализации
 * @param {string} defaultValue - возвращает defaultValue, если файла локализации не найдено, или нет такого ключа.
 * @description Функция перевода, ключи лежат в файле локализации
 */
export function lang(key: string, defaultValue?: string): string {
  if (typeof Luxms === 'undefined') return defaultValue || key;
  if (key in Luxms.lang) return Luxms.lang[key];

  if (Array.isArray(key)) {                                         // для lang`STR`
    return (key as any).map(k => Luxms.lang[k] ?? k).join('');
  }

  if (!(key in __failed_localization_reported)) {
    console.warn(`Failed localization of item ${key}`);
    __failed_localization_reported[key] = true;
  }
  return (defaultValue != null) ? defaultValue : key;
}


(window as any).lang = lang;        // deprecated: for templates

/**
 * @param {string} date -
 * @param {number} periodType - выбор формата для периода
 * @description Функция использует библиотеку moment.js, форматирует дату в форматы заданы в periodType.
 * 0 : format() // 1: ll LTS // 2:lll // 3:ll HH // 4:ll // 5:YYYY неделя w  // 6:YYYY MMM // 7:YYYY Q //8:YYYY]
 */
export function formatDate(date: string, periodType: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8): string {
  var m = moment(date);
  switch (periodType) {
    case 0:
      return m.format();            // Undefined
    case 1:
      return m.format('ll LTS');    // Second
    case 2:
      return m.format('lll');       // Minute
    case 3:
      return m.format('ll HH');            // Hour
    case 4:
      return m.format('ll');    // Day
    case 5:
      return m.format('YYYY неделя w ');    // Week
    case 6:
      return m.format('YYYY MMM');    // Month
    case 7:
      return m.format('YYYY Q');    // Quarter
    case 8:
      return m.format('YYYY');    // Year
  }
  return String(date);
}

/**
 * @param {string|number} value - число
 * @param {number} precision - количество символов после запятой, если число с плавающей точкой.
 * @description Преобразует число в формате '# ###', precision - округлит дробную часть. (если она есть)
 */
export function formatNum(value: IValue, precision?: number): string {
  if (typeof value === 'string') return value;
  if (value === undefined || value === null || !isFinite(value) || isNaN(value)) return '';
  if (precision === undefined) precision = 2;
  // var separator = '\u00A0';
  var separator = ' ';
  if (precision != -1) {
    value = value.toFixed(precision);
  }
  var a = ('' + value).split('.');
  a[0] = a[0]
      .split('').reverse().join('')
      .replace(/\d{3}(?=\d)/g, '$&' + separator)
      .split('').reverse().join('');

  if (a.length === 1) {
    return a[0];
  } else {
    // a[1] = a[1].replace(/0+$/, '') || "0";
    return (0 == parseInt(a[1], 10) ? a[0] : a.join('.'));
  }
}

// round and add unit/suffix to val19
export function makeValue(v: IValue, unit?: IUnit, digits?: number, config?: IVizelConfig, m?: IMetric) {
  if (v == null) return lang('no_data');
  if (typeof v === 'string') return v;
  let strValue: string = formatNum(v, digits == null ? 2 : digits);
  if (unit) {
    if (unit.config && unit.config.format && digits == null) strValue = formatNumberWithString(v, unit.config.format);
    if (unit.config && unit.config.valueMap && (v in unit.config.valueMap)) strValue = unit.config.valueMap[v];
    if (unit.value_prefix) strValue = unit.value_prefix + ' ' + strValue;
    if (unit.value_suffix) strValue = strValue + ' ' + unit.value_suffix;
  }
  if (config && m) {
    // выставляю формат по умолчанию
    const format = config.getFormat(m) ?? '-### ###.###'; // добавил "-" к формату, иначе вырезает его
    strValue = formatNumberWithString(v, format).toString();
  }
  return strValue;
}


//
(window as any).debug = function (arg) {
  if (arg !== undefined) {
    if (arg) {
      window.sessionStorage.setItem('DEBUG', 'true');
    } else {
      window.sessionStorage.removeItem('DEBUG');
    }
  }
  return !!(window.sessionStorage && window.sessionStorage.getItem('DEBUG'));
};


export function ruKbdToEng(s: string): string {
  return Array.prototype.map.call(s, c => ({
    'й': 'q', 'ц': 'w', 'у': 'e', 'к': 'r', 'е': 't', 'н': 'y', 'г': 'u', 'ш': 'i', 'щ': 'o', 'з': 'p',
    'ф': 'a', 'ы': 's', 'в': 'd', 'а': 'f', 'п': 'g', 'р': 'h', 'о': 'j', 'л': 'k', 'д': 'l', 'ж': ';',
    'я': 'z', 'ч': 'x', 'с': 'c', 'м': 'v', 'и': 'b', 'т': 'n', 'ь': 'm', 'б': ',', 'ю': '.',
  })[c] || c).join('');
}

/**
 * @param {string} value - строка обработки
 * @param {string} pattern - вхождение символов
 * @description Функция умеет искать вхождения с ошибкой ввода переключения языка ( search("найди меня",'yfq')=>true)
 */
export function search(value: string, pattern: string): boolean {
  pattern = pattern.toLowerCase();
  value = value.toLowerCase();
  if (pattern.length >= 3) {
    pattern = ruKbdToEng(pattern);
    value = ruKbdToEng(value);
  }
  return value.indexOf(pattern) !== -1;
}

/**
 * @param {string} str - исходная строка с заменой
 * @param {object} patterns - шаблоны для замены и значения
 * @param {object} defaultFormats - форматы для чисел
 * @description Заменяет вхождения в строке начинающиеся на знак процента на их значения
 */
export function stringSubstitute(str: string, patterns: { [id: string]: ((p: string, fmt?: string) => string | number | void) | string | number}, defaultFormats?: { [id: string]: string }): string {
  const entries = Object.keys(patterns).sort((a, b) => b.length - a.length);
  const re = new RegExp('%(?:{(.+?)})?(' + entries.join('|') + ')', 'g');
  return (str as any ?? '').replace(re, (p, format: string, e: string) => {
    const v = typeof patterns[e] === 'function' ? (patterns[e] as any)(e, format) : patterns[e];
    if (typeof v === 'number') {
      format = format ?? defaultFormats?.[e];                                                       // формат либо задан явно, либо через defaultFormats
      return format ? formatNumberWithString(v, format).toString() : v;
    } else if (typeof v === 'string') {
      return v;
    } else if (v instanceof String) {
      return v.toString();
    } else {
      return p;                                                                                     // Не получилось подобрать значение
    }
  });
}


const CYRILLIC_TO_LATIN = {
  'й': 'j',
  'ц': 'c',
  'у': 'u',
  'к': 'k',
  'е': 'e',
  'н': 'n',
  'г': 'g',
  'ш': 'sh',
  'щ': 'sch',
  'з': 'z',
  'х': 'h',
  'ъ': '',
  'ф': 'f',
  'ы': 'y',
  'в': 'v',
  'а': 'a',
  'п': 'p',
  'р': 'r',
  'о': 'o',
  'л': 'l',
  'д': 'd',
  'ж': 'zh',
  'я': 'ya',
  'ч': 'ch',
  'с': 's',
  'м': 'm',
  'и': 'i',
  'т': 't',
  'ь': '',
  'б': 'b',
  'ю': 'u',
};

/**
 * @param {string} s - строка текста
 * @description Функция переводит кириллицу на латиницу и заменяет пробелы на _
 */
export function idify(s: string): string {
  if (!s) return '';
  s = String(s).toLowerCase().replace(/[^a-zA-Z0-9]/g, (c) => (CYRILLIC_TO_LATIN[c] ?? '_'));
  if (s.match(/^\d/)) s = '_' + s;
  return s;
}

/**
 * @param {string[]} ss - массив строк текста
 * @return {string[]}
 * @description Функция переводит кириллицу на латиницу и заменяет пробелы на _, если в массиве содержаться копии, к каждой добавит _{ЧИСЛО копий}
 */
export function idifyMany(ss: string[]): string[] {
  let h: { [s: string]: boolean } = {'': true};
  return ss.map(s => {
    s = idify(s);
    if (h[s]) {                                                                                     // уже есть такой
      let i = 1;
      while (h[s + '_' + i]) i++;
      s = s + '_' + i;
    }
    h[s] = true;
    return s;
  });
}

/**
 * @description Функция проверяет ширину или длину экрана, если меньше = true
 * @return {boolean}
 */
export function isSmallPhone(): boolean {
  // var $body = $('body');
  // var minScreenSize = Math.min($body.width(), $body.height());
  const minScreenSize = Math.min(window.innerWidth, window.innerHeight);
  return minScreenSize <= 600;
}
/**
 * @param e - IEntity
 * @description проверяет пришёл ли ей объект, и имеет ли этот объект ключ axisId === 'metrics'
 */
export function IS_M(e: IEntity): e is IMetric {
  return !!e && (typeof e === 'object') && (e.axisId === 'metrics');
}

/**
 * @param e - IEntity
 * @description проверяет пришёл ли ей объект, и имеет ли этот объект ключ axisId === 'locations'
 */
export function IS_L(e: IEntity): e is ILocation {
  return !!e && (typeof e === 'object') && (e.axisId === 'locations');
}

/**
 * @param e - IEntity
 * @description проверяет пришёл ли ей объект, и имеет ли этот объект ключ axisId === 'periods'
 */
export function IS_P(e: IEntity): e is IPeriod {
  return !!e && (typeof e === 'object') && (e.axisId === 'periods');
}

/**
 * @param es - IEntity[]
 * @description проверяет пришёл ли ей массив, и у первого эл-т массива проверяет ключ axisId === 'metrics'
 */
export function IS_MS(es: IEntity[]): es is IMetric[] {
  return Array.isArray(es) && IS_M(es[0]);
}

/**
 * @param es - IEntity[]
 * @description проверяет пришёл ли ей массив, и у первого эл-т массива проверяет ключ axisId === 'locations'
 */
export function IS_LS(es: IEntity[]): es is ILocation[] {
  return Array.isArray(es) && IS_L(es[0]);
}

/**
 * @param es - IEntity[]
 * @description проверяет пришёл ли ей массив, и у первого эл-т массива проверяет ключ axisId === 'periods'
 */
export function IS_PS(es: IEntity[]): es is IPeriod[] {
  return Array.isArray(es) && IS_P(es[0]);
}

/**
 * @param z - IEntity
 * @param y - IEntity
 * @param x - IEntity
 * @description ищет среди 3х объектов, объект с ключом axisId === 'metrics', идет по порядку z-y-x
 */
export function FIND_M(z: IEntity, y: IEntity, x: IEntity): IMetric | null {
  return IS_M(z) && z || IS_M(y) && y || IS_M(x) && x || null;
}

/**
 * @param z - IEntity
 * @param y - IEntity
 * @param x - IEntity
 * @description ищет среди 3х объектов, объект с ключом axisId === 'locations', идет по порядку z-y-x
 */
export function FIND_L(z: IEntity, y: IEntity, x: IEntity): ILocation | null {
  return IS_L(z) && z || IS_L(y) && y || IS_L(x) && x || null;
}

/**
 * @param z - IEntity
 * @param y - IEntity
 * @param x - IEntity
 * @return {IPeriod | null}
 * @description ищет среди 3х объектов, объект с ключом axisId === 'periods', идет по порядку z-y-x
 */
export function FIND_P(z: IEntity, y: IEntity, x: IEntity): IPeriod {
  return IS_P(z) && z || IS_P(y) && y || IS_P(x) && x || null;
}

/**
 * @param zs - IEntity[]
 * @param ys - IEntity[]
 * @param xs - IEntity[]
 * @return {IMetric[] | null}
 * @description ищет среди 3х массивов объектов, массив объектов с ключом axisId === 'metrics'
 */
export function FIND_MS(zs: IEntity[], ys: IEntity[], xs: IEntity[]): IMetric[] | null {
  return IS_MS(zs) && zs || IS_MS(ys) && ys || IS_MS(xs) && xs || null;
}

/**
 * @param zs - IEntity[]
 * @param ys - IEntity[]
 * @param xs - IEntity[]
 * @return {ILocation[] | null}
 * @description ищет среди 3х массивов объектов, массив объектов с ключом axisId === 'locations'
 */
export function FIND_LS(zs: IEntity[], ys: IEntity[], xs: IEntity[]): ILocation[] | null {
  return (IS_LS(zs) && zs || IS_LS(ys) && ys || IS_LS(xs) && xs || null);
}

/**
 * @param zs - IEntity[]
 * @param ys - IEntity[]
 * @param xs - IEntity[]
 * @return {IPeriod[] | null}
 * @description ищет среди 3х массивов объектов, массив объектов с ключом axisId === 'periods'
 */
export function FIND_PS(zs: IEntity[], ys: IEntity[], xs: IEntity[]): IPeriod[] | null {
  return (IS_PS(zs) && zs || IS_PS(ys) && ys || IS_PS(xs) && xs || null);
}

export function binarySearch<T>(arr: T[], cmpWith: (a: T) => number): T {
  let start: number = 0, end: number = arr.length - 1;
  while (start <= end) {
    const ind: number = (end + start) >> 1;
    switch (sign(cmpWith(arr[ind]))) {
      case -1:
        end = ind - 1;
        break;
      case 0:
        return arr[ind];
      case 1:
        start = ind + 1;
        break;
    }
  }
  return null;
}

const AXES_ABBR = {
  M: 'metrics',
  L: 'locations',
  P: 'periods',
};

// return axis name: 'xs' for 0, 'ys' for 1, 'zs' for 2, 'aas' for 3, 'abs' for 4
function getAxisName(idx: number): string {
  return ['xs', 'ys', 'zs', 'aas', 'abs', 'acs', 'aes'][idx] || 'unknown';
}

export function makeAxesOrderFromArray(axes: string[]): IAxesOrder {
  for (let i = 0; i < axes.length; i++) axes[getAxisName(i)] = axes[axes.length - i - 1];
  return axes;
}

export function makeAxesOrderFromUrl(ao: string, loc?: boolean): IAxesOrder {
  let axes: string[];
  if (!ao) axes = loc ? ['metrics', 'locations', 'periods'] : ['locations', 'metrics', 'periods'];
  else if (ao && ao.match(/^[MLP][MLP][MLP]$/)) axes = ao.split('').map(axesName => AXES_ABBR[axesName]);
  else axes = ao.split(';');
  return makeAxesOrderFromArray(axes);
}

export function axesOrderStringify(ao: IAxesOrder): string {
  if (ao.length === 3 && ao.indexOf('metrics') + ao.indexOf('locations') + ao.indexOf('periods') === 3) {
    return ao.map(a => ({metrics: 'M', locations: 'L', periods: 'P'})[a]).join('');
  }
  return ao.join(';');
}

export function axesOrderSwap(axesOrder: IAxesOrder, a1: string | number, a2: string | number): IAxesOrder {
  if (typeof a1 === 'string') a1 = axesOrder.indexOf(axesOrder[a1]);
  if (typeof a2 === 'string') a2 = axesOrder.indexOf(axesOrder[a2]);
  let result = axesOrder.slice(0);
  [result[a1], result[a2]] = [result[a2], result[a1]];
  return makeAxesOrderFromArray(result);
}

export interface IAggregate {
  data: any[];
  put: (record: { [id: string]: string | number }) => IAggregate;
  get: (measure: string | number) => string | number | undefined;
}

// ф-ия агрегации для создания кеша осей x-y-z- aggregate
export const _aggregate = function (): IAggregate {
  return {
    data: [],
    put: function (record): IAggregate {
      this.data.push(record);
      return this;
    },
    get: function (measure: string | number): string | number | null {
      const len = this.data.length - 1;
      return this.data[len]?.[measure] ?? null;
    },
  };
};

/**
 * @param {IEntity} axis - сущность оси
 * @description Вынимает все данные с сущности оси, игнорируя measures
 */
export function getDataInAxis(axis: IEntity): { columns: string[], filters: { [id: string]: IValue[] } } {
  const columns: string[] = [];
  const filters: { [id: string]: IValue[] } = {};

  if (!axis?.axisIds) return {columns, filters};

  axis.axisIds.forEach((axisId, i) => {
    if (axisId === 'measures') return;

    const formula: string = axis.formula[i];
    const id = axis.ids[i];

    columns.push(formula);
    if (!formula.match(/:/)) {
      if (!filters[axisId]) filters[axisId] = [];
      filters[axisId].push(id);
    }
  });

  return {columns, filters};
}

/**
 * @param {IEntity} xAxis - сущность оси
 * @param {IEntity} yAxis - сущность оси
 * @param {IEntity} zAxis - сущность оси
 * @description ищет на осях в массиве axisIds measures - и возвращает его id
 */
export function getMeasureId(xAxis: IEntity, yAxis: IEntity, zAxis?: IEntity): string | number {
  const idx = (xAxis?.axisIds || []).indexOf('measures');
  const idy = (yAxis?.axisIds || []).indexOf('measures');
  const idz = (zAxis?.axisIds || []).indexOf('measures');
  if (idx >= 0) return xAxis.ids[idx];
  if (idy >= 0) return yAxis.ids[idy];
  if (idz >= 0) return zAxis.ids[idz];
  return null;
}

/**
 * @description вспомогательная ф-ия для лукапа, для разбития данных по осям
 */
export function joinDataLookup(rows: Array<string | number>, columns: { name: string }[]): any {
  const result = {};
  if (!rows.length || !columns.length) return result;
  for (let i = 0; i < columns.length; i++) {
    const key = columns[i].name;
    const value = rows[i];
    result[key] = value;
  }
  return result;
}

/**
 * Исправляет конфигурацию меши в случае, если она задана по стандартам версии 1, как sum_column
 * @deprecated
 * @param formula
 */
export function fixMeasureFormula(formula: string): string {
  if (formula.match(/^sum_\w+$/)) formula = `sum(${formula.slice(4)}):${formula}`;                  // id типа sum_x преобразовываем в sum(x):sum_x
  if (formula.match(/^count_\w+$/)) formula = `count(${formula.slice(6)}):${formula}`;
  if (formula.match(/^avg_\w+$/)) formula = `avg(${formula.slice(6)}):${formula}`;

  // для aggfn не надо указывать формулу
  if (formula.match(/^aggfn_\w+$/)) formula = `${formula.slice(6)}:${formula}`;
  return formula;
}

/**
 * Пытается вытащить id из записи меши в конфиге разных видов - sum(column), func(column):id и т.д.
 * @param formula
 */
export function extractMeasureId(formula: string): string {
  if (formula.match(/:(\w+)$/)) return RegExp.$1;                                                    // если указано sum(y):x то id будет равен x
  if (formula.match(/^\w+\((.+)\)$/)) return RegExp.$1;                                             // если формула типа "func_name(x..." то id также будет равен x, учитываем только первый аргумент
  if (formula.match(/^\((\w+)\)/)) return RegExp.$1;                                                // (col)

  // Надо добавлять разные проверки
  // if (!match) return String(expression).startsWith('if') ? expression.split(':').pop() : expression;

  return formula;                                                                                   // непонятно, может это просто айдишка
}

/**
 * @param {string} formula - формула мешы
 * @param {Array}  columns - массив объектов, c ключом 'name' встречающийся в формуле
 * @param {object} style - стили из конфига datasource
 * @description Функция ищет title, встречающийся в формуле, ищет в массиве columns + ищет в style
 */
export function extractMeasureTitle(formula: string, columns: { id: IValue, title: string, name?: string }[], style?: any): string {
  const id = extractMeasureId(formula);
  if (typeof style?.measures?.[id]?.title === 'string') return style.measures[id].title;

  formula = formula.replace(/:\w+$/, '');                                                           // откусываем ':id' с конца формулы

  const match = formula.match(/^(\w+)\((.+)\)$/);                                                   // fn_name(colums formula)
  if (match) {
    const fn = match[1], expr = match[2];
    return ({SUM: '∑', COUNT: 'N', AVG: 'μ'}[fn.toUpperCase()] || fn.toLowerCase()) + ' ' +
        expr.replace(/\w+/g, (ident) => columns.find(c => c.name === ident)?.title || ident);
  }

  return formula.replace(/\w+/g, (e) => $eid(columns, e)?.title || e);
}

/**
 *
 * @param {string} cfgUrl - url из конфига визеля
 * @param schemaName - имя датасет
 * @description Формирует ссылку на ресурсы исходя из конфига визеля
 */
export function makeResourceUrl(cfgUrl: string, schemaName: string): string {
  let url: string = cfgUrl;
  if (url.slice(0, 4) === 'res:') {           // resources
    url = url.slice(4);

    if (url.match(/^(ds_[a-zA-Z0-9_]+)?:(.+)$/)) {
      schemaName = RegExp.$1;
      url = RegExp.$2;
    }

    url = AppConfig.fixRequestUrl(`/srv/resources/${schemaName}/${url}`);
  }
  return url;
}


/**
 * При необходимости возвращает строчку в кавычках
 * необходимость случается, когда есть неанглийские символы, либо разный регистр букв
 * @param s - название схемы/таблицы/столбца
 */
export function quotifySql(s: string): string {
  if (/^(([a-z0-9_]+)|([A-Z0-9_]+))$/.test(s)) return s;                                            // английские буквы одного регистра
  return '"' + s + '"';
}
