import React from 'react';
import useService from '../useService';

import {AppConfig, extractErrorMessage, IUrl, srv, UrlState} from '@luxms/bi-core';
import AppLogo from '@luxms/bi-face/AppLogo';
import Menu from '@luxms/bi-face/Menu';

import {ProviderService} from '../../services/ProviderService';
import {IMainToolbarVM, MainToolbarVC} from '../../view-controllers/panels/MainToolbarVC';
import {DatasetsByTopicsService, ITopic} from '../../../srx/services/DatasetsByTopicsService';
import EditModeVC, {IEditModeModel} from '../../view-controllers/EditModeVC';
import {DASHBOARD_ICONS} from '../../const/DashboardIcons';
import ContextMenu from '../components/ContextMenu/ContextMenu';
import {DashboardByTopicsService, IRawDashboardTopicMode} from '../../services/ds/DashboardByTopicsService';

import {lang} from '../../utils/utils';
import cn from 'classnames';
import {$eid} from '../../libs/imdas/list';

import {OptionsProvider} from '../../config/OptionsProvider';
import {AlertsVC} from '../../view-controllers/AlertsVC';
import SVGIcon from '../components/SVGIcon';
import {IRawDashboard} from '@luxms/bi-core/dist/repositories/ds';
import {DsStateService} from "../../services/ds/DsStateService";

const skin = require('../../../src/skins/skin.json');

const Logo = React.memo(({schema_name}: { schema_name: string }) => {
  const provider = useService<ProviderService>(ProviderService);
  const dsRes = useService<srv.ds.ResourcesService>(srv.ds.ResourcesService, 'ds_res');
  const resources = useService<srv.ds.ResourcesService>(srv.ds.ResourcesService, schema_name);
  let isFull = false;

  if (provider.error) return null;
  if (provider.loading || dsRes.loading || resources.loading) return null;

  let image = require('../../../assets/images/logo.png');
  let appName = AppConfig.getInstance().getModel().projectTitle ?? skin.appName ?? 'Luxms BI';
  let appNameDesc: any = null; /* 'аналитические решения'; */

  if (appName && appName[0] === '\'' && appName[appName.length - 1] === '\'') {                     // due to css issues appName may be quoted in skin.json
    appName = appName.slice(1, -1);
  }

  if (dsRes.find(r => r.alt_id === 'thumbnail.svg')) image = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnail.svg');
  else if (dsRes.find(r => r.alt_id === 'thumbnail.png')) image = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnail.png');
  else if (dsRes.find(r => r.alt_id === 'thumbnailFull.svg')) {
    image = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnailFull.svg');
    isFull = true;
  }

  if (resources.find(r => r.alt_id === 'logo.svg')) {
    image = AppConfig.fixRequestUrl(`/srv/resources/${schema_name}/logo.svg`);
  }

  return (
    <AppLogo icon={image} altImage={appName} className={isFull ? 'full' : ''}>
      {!isFull && <AppLogo.Text major>{appName}</AppLogo.Text>}
      {!isFull && appNameDesc && <AppLogo.Text>{appNameDesc}</AppLogo.Text>}
    </AppLogo>);
});


const IconPackMenu: React.FC<any> = ({onPickIcon}): JSX.Element => {
  const icons = Object.getOwnPropertyNames(DASHBOARD_ICONS);

  return (
    <ul className="DsShellLeftPane__IconPack">
      {icons.map(item => <li className="DsShellLeftPane__IconPacksItem" key={item}
                             onClick={() => onPickIcon(item)}>{DASHBOARD_ICONS[item]}</li>)}
    </ul>
  );
};

interface IDsShellLeftPane {
  readonly schema_name: string;
}

interface DsShellLeftPanelState {
  readonly editMode: boolean;
  readonly datasetTopics: ITopic[];
  readonly dashboardTopics: IRawDashboardTopicMode[];
  readonly mainToolBar: IMainToolbarVM;
  readonly dashboards: IRawDashboard[];
  //
  readonly newDashboardTitle: string;
  readonly newDashboardIcon: string;
  readonly currentDashBoard: number;
  readonly visible: boolean;
  //
  readonly editableItemId: number;
  readonly editableItemTitle: string;
  //
  readonly separatorMove: boolean;
  readonly deltaMove: string;
}

export class DsShellLeftPane extends React.PureComponent<IDsShellLeftPane, DsShellLeftPanelState> {
  private readonly _mainToolbarVC: MainToolbarVC;
  private readonly _dsStateService: DsStateService; // для mlp title'ов %l, %m
  private readonly _datasetsByTopicsService: DatasetsByTopicsService;
  private readonly _dashboardByTopicsService: DashboardByTopicsService;
  private readonly _urlState: UrlState;
  private readonly _editModeVC: EditModeVC;

  public readonly state: DsShellLeftPanelState;

  public constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      //
      datasetTopics: [],
      dashboardTopics: [],
      dashboards: [],
      //
      mainToolBar: null,
      newDashboardTitle: '',
      currentDashBoard: null,
      newDashboardIcon: null,
      visible: false, // visible icon
      editableItemId: -1,
      editableItemTitle: '',
      //
      separatorMove: false,
      deltaMove: localStorage.getItem('preferences.LeftPane') ?? '20%',
    };

    const {schema_name} = props;
    this._urlState = UrlState.getInstance();
    this._editModeVC = EditModeVC.getInstance();
    this._mainToolbarVC = new MainToolbarVC(schema_name);
    this._datasetsByTopicsService = DatasetsByTopicsService.getInstance();
    this._dashboardByTopicsService = new DashboardByTopicsService(schema_name);
    this._dsStateService = DsStateService.createInstance(schema_name);
  }

  public componentDidMount() {
    this._editModeVC.subscribeUpdatesAndNotify(this._onEditUpdate);
    this._mainToolbarVC.subscribeUpdatesAndNotify(this._onServiceUpdate);
    this._datasetsByTopicsService.subscribeUpdatesAndNotify(this._onServiceUpdate);
    this._dashboardByTopicsService.subscribeUpdatesAndNotify(this._onServiceUpdate);
    this._dsStateService.subscribe('metrics locations periods', this._onServiceUpdate);
  }

  public componentWillUnmount() {
    this._editModeVC.unsubscribe(this._onEditUpdate);
    this._mainToolbarVC.unsubscribe(this._onServiceUpdate);
    this._datasetsByTopicsService.unsubscribe(this._onServiceUpdate);
    this._dashboardByTopicsService.unsubscribe(this._onServiceUpdate);
    this._dsStateService.unsubscribe(this._onServiceUpdate);
  }

  private _onEditUpdate = (model: IEditModeModel): void => {
    if (model.loading || model.error) return;
    this.setState({editMode: model.editMode});
  }

  private _onServiceUpdate = (): void => {
    const modelToolbar = this._mainToolbarVC.getModel();
    const modelDatasetTopicServ = this._datasetsByTopicsService.getModel();
    const modelDashboardTopicServ = this._dashboardByTopicsService.getModel();
    const modelDsStateSrv = this._dsStateService.getModel();
    if (modelToolbar.loading || modelDashboardTopicServ.loading || modelDatasetTopicServ.loading || modelDsStateSrv.loading) return;
    const datasetTopics = modelDatasetTopicServ.topics;
    const dashboardTopics = modelDashboardTopicServ.topics;
    const dashboards = modelDashboardTopicServ.dashboards;
    this.setState({mainToolBar: modelToolbar, datasetTopics, dashboardTopics, dashboards});
  }

  // handle fn
  private _onPickIcon = async (iconName: string, dashBoardId: number, dashBoardConfig: any): Promise<void> => {
    if (dashBoardId && dashBoardConfig) {
      try {
        this._dashboardByTopicsService._dashboardsService.updateOne(dashBoardId, {
          config: {...dashBoardConfig, icon: iconName}
        });
        await this._dashboardByTopicsService._dashboardsService.whenReady();
      } catch (error) {
        AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(error));
      }
    } else this.setState({newDashboardIcon: iconName});
    this.setState({visible: false});
  }
  private _onPointerDown = (e: React.PointerEvent<HTMLDivElement>): void => {
    const el = e.currentTarget;
    el.setPointerCapture(e.pointerId);
    e.stopPropagation();
    this.setState({separatorMove: true});
  }
  private _onPointerUp = (e: React.PointerEvent<HTMLDivElement>): void => {
    const el = e.currentTarget;
    el.releasePointerCapture(e.pointerId);
    e.stopPropagation();
    this.setState({separatorMove: false});
    localStorage.setItem('preferences.LeftPane', this.state.deltaMove);
  }
  private _onPointerMove = (e: React.PointerEvent<HTMLDivElement>): void => {
    if (!this.state.separatorMove) return;
    e.stopPropagation();
    e.preventDefault();
    const deltaMove = e.clientY - 73;
    if (deltaMove + 250 > window.innerHeight || deltaMove < 50) return;
    this.setState({deltaMove: deltaMove + 'px'});
  }
  private _onDragStart = (e): void => {
    e.preventDefault();
    e.stopPropagation();
  }
  private _onKeyUpEditableItem = async (event, id: number): Promise<void> => {
    const title = event.target.value;
    if (event.key === 'Enter') {
      if (!title) return;
      try {
        this._dashboardByTopicsService._dashboardsService.updateOne(id, {title});
        await this._dashboardByTopicsService._dashboardsService.whenReady();
        this._clearEditItem();
      } catch (error) {
        AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(error));
      }
    } else if (event.key === 'Escape') this._clearEditItem();
  }
  private _onCreateNewDashboard = async (event: any): Promise<void> => {
    const title = event.target.value;
    const {newDashboardIcon} = this.state;
    if (event.key === 'Enter') {
      if (!title) return;
      try {
        this._dashboardByTopicsService._dashboardsService.create({
          title: title,
          config: {icon: newDashboardIcon, viewport: {w: 12, h: 12}}
        });
        await this._dashboardByTopicsService._dashboardsService.whenReady();
        this._clearInput();
      } catch (error) {
        AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(error));
      }

    } else if (event.key === 'Escape') this._clearInput();
  }
  private _onVisibleIcon = (id: number): void => {
    const {editMode, currentDashBoard, visible} = this.state;
    if (!id || !editMode) return;
    if (currentDashBoard === id) this.setState({visible: !visible});
    else this.setState({visible: true, currentDashBoard: id});
  }
  private _clearInput = (): void => this.setState({newDashboardTitle: '', newDashboardIcon: 'noIcon'});
  private _clearEditItem = (): void => this.setState({editableItemId: -1, editableItemTitle: ''});
  // helper fn
  private _onContextMenu = (event: any, id: number): void => {
    event.stopPropagation();
    event.preventDefault();
    const {dashboards} = this.state;
    const dashboard = $eid(dashboards, id);
    const OptionsSrv: OptionsProvider = new OptionsProvider((dashboard.config?.options || []).slice(0));
    const isHidden = OptionsSrv.hasAnyOf('Hidden', 'TabHidden');
    ContextMenu.show(event, [
      {
        title: isHidden ? lang('show') : lang('Hidden'),
        action: () => {
          if (isHidden) OptionsSrv.removeOption('Hidden');
          else OptionsSrv.addOption('Hidden');
          this._dashboardByTopicsService._dashboardsService.updateOne(id, {
            config: {
              ...dashboard.config,
              options: OptionsSrv.getOptions()
            }
          });
        },
      },
      {
        confirm: 'да',
        title: lang('delete'),
        action: () => this._dashboardByTopicsService._dashboardsService.remove(id),
      }
    ], {arrow: false, placement: 'bottom-start'});
  }
  private _makeDashTitle = (title: string): string => {
    if (!title.match(/%l|%p|%m/gi)) return title;
    const {metrics, periods, locations} = this._dsStateService.getModel();
    const replaceTitle = title
      .replace(/%l/gi, locations[locations.length - 1]?.title ?? '')
      .replace(/%m/gi, metrics[metrics.length - 1]?.title ?? '')
      .replace(/%p/gi, periods[periods.length - 1]?.title ?? '');
    return replaceTitle;
  }

  // render fn
  private _renderDash = (dboard: IRawDashboard): JSX.Element => {
    const {editMode, editableItemId, editableItemTitle, currentDashBoard, visible, dashboards} = this.state;
    const id = dboard.id;
    const config = dboard?.config ?? {};
    const icon = config?.icon ?? 'noIcon';
    const iconDropdownVisible = id == currentDashBoard ? visible : false;

    // проверяю какой dashboard выбран
    const visibleDashboards = dashboards.filter(rawDashboard => !new OptionsProvider(rawDashboard.config.options).hasAnyOf('Hidden', 'TabHidden'));
    const visibleDashboard = !new OptionsProvider(dboard.config.options).hasAnyOf('Hidden', 'TabHidden');
    const urlState = this._urlState.getModel();
    let active = urlState.dboard === String(dboard.id) && urlState.route === '#dashboards';
    if (!urlState.dboard && urlState.route === '#dashboards') active = visibleDashboards[0]?.id === dboard.id;

    // показываю - не показываю isHidden dashboard
    if (!editMode && !visibleDashboard) return null;

    const url: IUrl = UrlState.getInstance().getModel();
    const dashboardsRoute = (url.route === '#dashboards' || url.route === '#dbuilder' || url.route === '#edt-dashboards') ? url.route : '#dashboards';
    const href = UrlState.getInstance().buildUrl({
      segment: 'ds',
      segmentId: this.props.schema_name,
      route: dashboardsRoute,
      dboard: dboard.id
    });
    const title = this._makeDashTitle(dboard.title);

    return (
      <Menu.Item
        key={'dashboard' + dboard.id}
        className={cn({isHiddenDashboard: !visibleDashboard})}
        icon={DASHBOARD_ICONS[icon]}
        active={active}
        title={title}
        onIconClick={editMode ? () => this._onVisibleIcon(id) : undefined}
        iconDropdown={<IconPackMenu onPickIcon={(iconName) => this._onPickIcon(iconName, id, config)}/>}
        iconDropdownVisible={iconDropdownVisible}
        iconDropdownOutsideClick={() => this.setState({visible: false})}
        iconDropdownProps={{placement: 'right'}}
        href={editMode ? null : href}
      >
        {
          editMode
            ? editableItemId === id
              ? <input className="needsToChangeGeneralInputStyles"
                       value={editableItemTitle}
                       onBlur={this._clearEditItem}
                       onKeyUp={e => this._onKeyUpEditableItem(e, id)}
                       autoFocus={true}
                       onChange={e => this.setState({editableItemTitle: e.target.value})}/>
              : <div onDoubleClick={() => this.setState({editableItemId: id, editableItemTitle: dboard.title})}
                     onContextMenu={e => this._onContextMenu(e, id)}>{dboard.title}</div>
            : title
        }
      </Menu.Item>
    );
  }

  public render() {
    const {schema_name} = this.props;
    let {datasetTopics, dashboardTopics, mainToolBar, editMode, deltaMove} = this.state;
    const {newDashboardTitle, currentDashBoard, visible, newDashboardIcon} = this.state;
    const withoutGroup = $eid(datasetTopics, -1);

    const newDboardID = -99;
    const mapButton = mainToolBar?.mapButton;
    const trendsButton = mainToolBar?.trendsButton;

    return (
      <Menu onCompact={() => console.log('compact')} onExpanded={() => console.log('expanded')}
            onHidden={() => console.log('hidden')} className="AppSider">
        <Menu.Header>
          <a href="#/ds/">
            <Logo schema_name={schema_name}/>
          </a>
        </Menu.Header>

        <Menu.Content className="dashboard" style={{minHeight: deltaMove, height: deltaMove, maxHeight: '90%'}}>
          <Menu.List>
            {
              dashboardTopics.map((topic) => {
                if (!topic?.children?.length) return null;
                if (topic.title === 'R o o t') return topic.children.map((dboard) => this._renderDash(dboard));
                return (
                  <Menu.SubList title={topic.title} treeLevel={1} key={`topic_${topic.id}`}>
                    {topic.children.map((dboard) => this._renderDash(dboard))}
                  </Menu.SubList>
                );
              })
            }

            {/* Новый дэшборд */}

            {editMode &&
            <Menu.Item title={lang('new_dashboard')}
                       icon={DASHBOARD_ICONS[newDashboardIcon]}
                       iconDropdown={<IconPackMenu onPickIcon={this._onPickIcon}/>}
                       onIconClick={() => this._onVisibleIcon(newDboardID)}
                       iconDropdownVisible={newDboardID === currentDashBoard ? visible : false}
                       iconDropdownOutsideClick={() => this.setState({visible: false})}
                       iconDropdownProps={{placement: 'right'}}>

              <input className="needsToChangeGeneralInputStyles"
                     placeholder={lang('new_dashboard')}
                     onBlur={() => this._clearInput()}
                     onKeyUp={this._onCreateNewDashboard}
                     value={newDashboardTitle}
                     onChange={e => this.setState({newDashboardTitle: e.target.value})}/>
            </Menu.Item>
            }

            {/* Карта */}

            {mapButton?.visible &&
            <Menu.Item icon={DASHBOARD_ICONS['map2']} active={mapButton.active} title={mapButton.title}
                       key={'mapButton' + mapButton.viewClassId} href={mapButton.href}>
              {mapButton.title}
            </Menu.Item>}

            {/* Тренды */}

            {trendsButton?.visible &&
            <Menu.Item icon={DASHBOARD_ICONS['trends']} active={trendsButton.active} title={trendsButton.title}
                       key={'trends' + trendsButton.viewClassId} href={trendsButton.href}>
              {trendsButton.title}
            </Menu.Item>}
          </Menu.List>
        </Menu.Content>

        <Menu.Separator style={{padding: '.5rem 0', margin: '1rem 0', listStyleType: 'none', cursor: 'n-resize'}}
                        onPointerDown={this._onPointerDown}
                        onPointerUp={this._onPointerUp}
                        onPointerMove={this._onPointerMove}
                        onDragStart={this._onDragStart}
        />

        <Menu.Content className="datasets" style={{maxHeight: '90%'}}>
          <Menu.List>
            {datasetTopics.filter(dt => dt !== withoutGroup).map(topic => {
              if (!topic.datasets.length) return null;
              return (<Menu.SubList title={topic.title} treeLevel={1} key={topic.id} icon={topic.config.icon ??
              <SVGIcon path={require('../../../assets/icons/folder.svg')}/>}>
                {topic.datasets.map(dataset => {
                  const urlState = this._urlState.getModel();
                  const active = urlState.segment === 'ds' && urlState.segmentId === dataset.schema_name;
                  return (
                    <Menu.Item title={dataset.title}
                               key={'datasetTopics' + dataset.id}
                               active={active}
                               icon={dataset.config.icon ? <SVGIcon path={dataset.config.icon}/> : undefined}
                               href={dataset.href}>
                      {dataset.title}
                    </Menu.Item>
                  );
                })}
              </Menu.SubList>);
            })}

            {withoutGroup?.datasets.filter(ds => ds.is_visible || editMode).map(dataset => {            /* тут копипейст - надо поправить */
              const urlState = this._urlState.getModel();
              const active = urlState.segment === 'ds' && urlState.segmentId === dataset.schema_name;
              return (
                <Menu.Item title={dataset.title} key={'datasetTopics' + dataset.id} active={active} href={dataset.href}>
                  {dataset.title}
                </Menu.Item>);
            })}
          </Menu.List>
        </Menu.Content>

        <Menu.Footer>
          <Menu.Toolkit>
            <Menu.ToggleControls>
              <Menu.Control mark="collapseMenu"/>
              <Menu.Control mark="expandMenu"/>
            </Menu.ToggleControls>
          </Menu.Toolkit>
        </Menu.Footer>
      </Menu>);
  }
}

export default DsShellLeftPane;