/**
 *
 *  A logic based on current state taken from url and dataset model
 *  Defines currently selected items like products, locations, periods
 *
 */

import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { BaseService, IUrl, UrlState, urlState } from '@luxms/bi-core';
import { $eid, $esid, getEntity } from '../../libs/imdas/list';
import { axesOrderStringify, makeAxesOrderFromUrl, nEntities, oneEntity } from '../../utils/utils';
import {
  IConfigHelper,
  IDashboard,
  IDashlet,
  IDashletsHelper,
  IDatasetModel,
  IDatasetServiceModel,
  IDsStateService,
  IDsState, IAxesOrder,
} from './types';
import { DatasetService } from './DatasetService';
import COLOR_PALETTE from '../../utils/color-palette';
const skin: any = require('../../skins/skin.json');
// [esix]: stops compiling! Cannot resolve...
// import { IGeo, ILocation, IMapFill, IMetric, IPeriod, IPeriodInfo, IPreset, ITag, Luxms } from '../../defs/bi';


class ColorRotator {
  private i = 0;

  public nextColor() {
    const colorPallete: string[] = skin.colorPallete ?? COLOR_PALETTE;
    const color = colorPallete[this.i];
    this.i = (this.i + 1) % colorPallete.length;
    return color;
  }

  public reset() {
    this.i = 0;
  }
}

const ROUTE_MAP = '#map';


function isGeoValid(geo: IGeo): boolean {
  return geo != null && geo.lat != null && geo.lng != null && geo.zoom != null;
}

function getDefaultAxesOrder(dataset: IDatasetModel, url: IUrl): IAxesOrder {
  const yAxis = dataset.getConfigHelper().getStringValue('startup.trends.yAxis');

  if (!url.ao) {
    if (yAxis === 'locations') {
      return makeAxesOrderFromUrl('MLP');
    } else if (yAxis === 'params') {
      return makeAxesOrderFromUrl('LMP');
    } else {
      return makeAxesOrderFromUrl(url.ao, url.loc);
    }
  } else {
    return makeAxesOrderFromUrl(url.ao, url.loc);
  }
}

function getMaxSelectableEntities(dataset: IDatasetModel, url: IUrl): {locationsCount: number, metricsCount: number} {
  const ch = dataset.getConfigHelper();
  const trendsMaxVizelCount: number = ch.getIntValue('trends.maxVizelCount', 3);
  const dashboardsPanelLocations: string = ch.getStringValue('dashboards.panel.locations') || ch.getStringValue('dashboard.panel.locations') || 'single';

  let metricsCount: number = Infinity;
  let locationsCount: number = Infinity;

  const axesOrder = getDefaultAxesOrder(dataset, url);

  switch (url.route) {
    case '#trends':
      if (axesOrder.zs === 'metrics') metricsCount = trendsMaxVizelCount;
      if (axesOrder.zs === 'locations') locationsCount = trendsMaxVizelCount;
      break;

    case '#map':
      metricsCount = Infinity;
      locationsCount = Infinity;
      break;

    case '#dashboards':
      metricsCount = Infinity;
      locationsCount = (dashboardsPanelLocations === 'multi') ? Infinity : 1;
      break;
  }

  return {locationsCount, metricsCount};
}


function buildDatasetTitle(dataset: IDatasetModel, route: string, metrics: IMetric[], locations: ILocation[], periods: IPeriod[], dboard: IDashboard): string {
  const template: string = dataset.getDatasetTitleTemplate(route);
  const repl = {
    m: metrics.length ? oneEntity(metrics).title : '',
    p: metrics.length ? oneEntity(metrics).title : '',
    l: locations.length ? oneEntity(locations).title : '',
    t: periods.length ? oneEntity(periods).title : '',
    d: dboard ? dboard.title : '',
    D: dataset.title,
  };
  return template.replace(/%(\d*)([mpltdD])/g, (fld, number, entityName) => {
    if (number) {
      const n: number = parseInt(number);
      let s: string = repl[entityName];
      if (s.length > number)
        s = s.substr(0, n - 3) + '...';
      return s;
    } else {
      return repl[entityName];
    }
  });
}


function parseDescriptionTags(s: string): string {
  if (!s) {
    return s;
  }

  const tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
  };
  s = s.replace(/[&<>]/g, (tag) => tagsToReplace[tag] || tag);
  // s = s.replace(/\n/g, '<br/>');       // <pre>
  s = s.replace(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/g,
    function (url) {
      return `<a class="light" href="${url}" rel="noopener" target="_blank">${url}</a>`;
    });

  return s;
}


function createTagGroupLocations(dataset: IDatasetModel, url: IUrl): ILocation[] {
  const ch = dataset.getConfigHelper();
  const lh = dataset.locationsHelper;
  let tagGroups: string[] = ch.getStringArray('panel.locations.tagGroupsTab', []);

  if (tagGroups.includes('*')) tagGroups = Object.keys(lh.tagAxes).sort((s1, s2) => s1.localeCompare(s2)); // * means `all groups`
  tagGroups = tagGroups.filter(tagGroup => !!lh.tagAxes[tagGroup]);

  let ls = dataset.locations;
  let title = '';

  for (let tagGroup of tagGroups) {
    ls = ls.filter(l => l.getTagByGroupId(tagGroup));                                               // take only locations have such tag
    let tags = url[tagGroup] ? url[tagGroup].split(',') : [];                                       // if none present in url => means none!

    if (tags.length) {
      title += title ? ', ' : '';
      title += tagGroup + ':' + tags.slice(0, 3).join(', ');
      if (tags.length > 3) title += '...';
    }

    ls = ls.filter(l => tags.includes(l.getTagByGroupId(tagGroup)?.id));
  }


  return [{
    axisId: 'locations',
    loc_id: -1,
    id: 'AGGREGATE_' + Math.random(),                                                               // TODO: compare AGGREGATE id by special code comparing its children
    parent_id: null,
    tree_level: 0,
    title,
    config: {},
    parent: null,
    children: ls,
    spatials: [],
    card: null,
    is_point: 0,
    latitude: 0,
    longitude: 0,
    is_hidden: 0,
    srt: 0,
    src_id: tagGroups.join(';'),
    rawTags: [],
    addTag: (tag: ITag) => null,
    getTags: (): ITag[] => [],
    getTag: (id: string | number): ITag => null,
    getTagByGroupId: (tagGroupId: string): ITag => null,
    is_aggregator: true,
    getAltTitle: (titleType: string) => title,
  }];
}


function createInitialLocations(dataset: IDatasetModel, url: IUrl, locationsCount): {formulaLocations: ILocation[], locations: ILocation[]} {
  const formulaLocations: ILocation[] = nEntities($esid(dataset.locations, url.locations || []), locationsCount);
  let locations: ILocation[] = formulaLocations;

  // TODO: url._tagGroups array of tags

  if (url._tagGroups) {
    locations = createTagGroupLocations(dataset, url);
  }

  // let lh: ILocationsHelper = dataset.locationsHelper;
  // const tagAxes: IAxis<any>[] = (Object as any).values(lh.tagAxes);
  // for (let axis of tagAxes) {
  //   const {axisId} = axis;
  //   if (axisId in url) {          // has filter by tagged
  //     const idsExpression: string = url[axisId];
  //     const ids: string[] = idsExpression ? idsExpression.split(',') : [];
  //     const ts = $esid(axis.entities, ids);
  //     locations = locations.filter(l => {
  //       const t = l.getTagByGroupId(axisId);
  //       return (ts.indexOf(t) !== -1);
  //     });
  //   }
  // }

  return {formulaLocations, locations};
}


function createInitialStateModel(dataset: IDatasetModel, url: IUrl): IDsState {
  const ch: IConfigHelper = dataset.getConfigHelper();
  const dh: IDashletsHelper = dataset.dashletsHelper;

  const {locationsCount, metricsCount} = getMaxSelectableEntities(dataset, url);

  const autoscale: boolean = !!url.autoscale;

  let chartType: string;
  if (isObject(url) && isObject(url.map) && isString(url.map.vizelType)) {
    chartType = url.map.vizelType;
  } else {
    chartType = (ch.getValue('map.clusteringMode') !== 'native' && !ch.getBoolValue('map.displayPinsOnly')) ? 'bars' : 'pies';
  }

  const dash: IDashlet = (url.dash !== null) ? dh.getDash(url.dash) : null;
  const dboard = (url.dboard !== null) ? dh.getDashboard(url.dboard) : dh.dashboards[0];
  const geo: IGeo = isGeoValid(url.geo) ? url.geo : null;
  const {formulaLocations, locations} = createInitialLocations(dataset, url, locationsCount);
  const axesOrder = getDefaultAxesOrder(dataset, url);

  const mfEnabled: boolean = !!url.mf.e;
  const mfLocations: ILocation[] = url.mf && Array.isArray(url.mf.ls) ? $esid(dataset.locations, url.mf.ls) : [];
  const mfMetrics: IMetric = $eid(dataset.metrics, url.mf.m);
  const mapfill: IMapFill = {
    enabled: mfEnabled,
    locations: mfLocations,
    metric: mfMetrics,
  };

  let preset: IPreset = getEntity(dataset.presets, String(url.preset));
  let metrics: IMetric[] = [];

  if (Array.isArray(url.metrics) && url.metrics.length) {
    metrics = $esid(dataset.metrics, url.metrics);
  }

  if (metrics.length) {           // if valid metrics were set in url, we ignore preset
    preset = null;
  }

  if (url.route === ROUTE_MAP && preset && preset.getDimensions().length > 1) {
    preset = null;
  }

  if (preset) {                   // if previous step failed and we have preset - it will be metrics
    metrics = preset.metrics;
    // when preset is on, will be populated both, preset and metrics
  }

  metrics = nEntities(metrics, metricsCount);

  const periodInfo: IPeriodInfo = dataset.getPeriodInfoByRange(url.period.start, url.period.end, url.period.type);
  const periods: IPeriod[] = periodInfo.periods;
  const route: string = url.route;
  const mapMetricsPanelVisible: boolean = false;

  const datasetTitle: string = buildDatasetTitle(dataset, route, metrics, locations, periods, dboard);

  return {
    autoscale,
    chartType,
    dash,
    dboard,
    geo,
    locations,
    formulaLocations,
    axesOrder,
    mapfill,
    metrics,
    periodInfo,
    periods,
    preset,
    route,
    mapMetricsPanelVisible,
    datasetTitle,
    datasetDescriptionHTML: parseDescriptionTags(dataset.description),
    dataset,
  };
}


function createDefaultStateModel(ds: IDatasetModel): IDsState {
  const ch = ds.getConfigHelper();
  const url: IUrl = ch.getEnterUrl(ds.schema_name);
  const state = createInitialStateModel(ds, url);
  return state;
}


interface IDepsModels {
  dataset: IDatasetServiceModel;
  url: IUrl;
}


export class DsStateService extends BaseService<IDsState> implements IDsStateService {
  private _id: string;
  private _url: IUrl = null;
  private _dataset: IDatasetModel = null;
  private mcr: ColorRotator = new ColorRotator();    // metrics color rotator
  private lcr: ColorRotator = new ColorRotator();    // locations color rotator
  private pcr: ColorRotator = new ColorRotator();    // periods color rotator

  private constructor(datasetId: string | number) {
    super({
      loading: true,
      error: '',
      autoscale: false,
      chartType: 'columns',           // 'columns', 'bars', 'pies'
      dash: null,
      dboard: null,
      geo: null,
      locations: [],
      formulaLocations: [],
      axesOrder: makeAxesOrderFromUrl('LMP'),
      mapMetricsPanelVisible: false,
      mapfill: null,
      metrics: [],
      periodInfo: null,
      periods: [],
      preset: null,
      route: '',
      datasetTitle: '',
      datasetDescriptionHTML: '',
      dataset: null,
      customConfig: {},
    });
    this._id = String(datasetId);

    // initialize
    this._url = urlState.getModel();

    this._addDependencies({
      dataset: DatasetService.createInstance(datasetId),
      url: urlState.retain(),
    });
  }

  protected _onDepsReadyAndUpdated(depsModels: IDepsModels, prevDepsModels: IDepsModels): any {
    const newUrl: IUrl = depsModels.url;
    const prevUrl: IUrl | null = this._model.loading ? null : prevDepsModels.url;                                       // after loading must fully initiate
    const ds: IDatasetModel | null = depsModels.dataset.dataset;
    const prevDataset: IDatasetModel | null = prevDepsModels.dataset ? prevDepsModels.dataset.dataset : null;

    this._url = newUrl;
    this._dataset = ds;

    // if (!prevUrl.period || newUrl.period !== prevUrl.period) {
    //   if (!this._url || this._url.period.start === null || this._url.period.end === null) {
    //     const periodInfo: IPeriodInfo = this._model.state.periodInfo;
    //     this._updateStateModel({
    //       periodInfo,
    //       periods: periodInfo.periods,
    //     });
    //   }
    // }

    if (!this.isActive()) {
      return this._updateWithData(createDefaultStateModel(ds));
    }

    // perform smart update with comparing

    const prevDsState: IDsState = this._model;
    const newDsState: IDsState = createInitialStateModel(this._dataset, newUrl);

    const metricsChanged: boolean = !isEqual(prevDsState.metrics, newDsState.metrics) || prevDsState.preset !== newDsState.preset;
    const locationsChanged: boolean = !isEqual(prevDsState.locations, newDsState.locations);
    const formulaLocationsChanged: boolean = !isEqual(prevDsState.formulaLocations, newDsState.formulaLocations);
    const periodsChanged: boolean = !prevDsState.periodInfo || !prevUrl || !isEqual(prevUrl.period, newUrl.period);                  // lite change

    // hack apply color
    if (metricsChanged) {
      if (newDsState.metrics.length == 0) {     // unselected everything: reset color counter
        this.mcr.reset();
      }
      if (prevDsState.preset !== newDsState.preset) {   // preset changed
        this.mcr.reset();
      }
      newDsState.metrics.forEach(m => {
        if (!prevDsState.metrics.includes(m)) {
          m.color = m.config?.color || this.mcr.nextColor();
        }
      });
    }

    if (locationsChanged || formulaLocationsChanged) {
      if (newDsState.locations.length === 0) {
        this.lcr.reset();
      }

      newDsState.formulaLocations.forEach(l => {
        if (!prevDsState.formulaLocations.includes(l)) {
          l.color = l.config?.color || this.lcr.nextColor();
        }
      });
    }

    const map = newUrl.map || {};
    const chartType: string = isObject(map) && ('vizelType' in map) ? map.vizelType : prevDsState.chartType;

    // update
    const autoscale = newDsState.autoscale;
    const geo = (!prevUrl || !isEqual(prevUrl.geo, newUrl.geo)) ? newDsState.geo : prevDsState.geo;
    const locations = locationsChanged ? newDsState.locations : prevDsState.locations;
    const formulaLocations = formulaLocationsChanged ? newDsState.formulaLocations : prevDsState.formulaLocations;
    const mapfill = (!prevUrl || !isEqual(prevUrl.mf, newUrl.mf)) ? newDsState.mapfill : prevDsState.mapfill;
    const metrics = metricsChanged ? newDsState.metrics : prevDsState.metrics;

    this._url = newUrl;
    this._updateWithData({
      ...newDsState,
      autoscale,
      chartType,
      dash: newDsState.dash,
      dboard: newDsState.dboard,
      geo,
      locations,
      formulaLocations,
      axesOrder: newDsState.axesOrder,
      mapfill,
      metrics,
      periodInfo: periodsChanged ? newDsState.periodInfo : prevDsState.periodInfo,
      periods: periodsChanged ? newDsState.periods : prevDsState.periods,
      preset: newDsState.preset,
      route: newDsState.route,
      dataset: this._dataset,
      mapMetricsPanelVisible: prevDsState.mapMetricsPanelVisible,
    });

    //
    // // colorize
    // this._model.locations.forEach(l => l.color = this.lcr.nextColor());
    // this._model.metrics.forEach(m => m.color = this.mcr.nextColor());
  }

  public getMaxParametersNumber(): number {
    const trendsMaxVizelCount: number = this._dataset.getConfigHelper().getIntValue('trends.maxVizelCount', 3);
    const axesOrder = makeAxesOrderFromUrl(this._url.ao, this._url.loc);
    switch (this._url.route) {
      case '#map':
        return Infinity;
      case '#trends':
        return axesOrder.zs === 'metrics' ? trendsMaxVizelCount : Infinity;
      default:
        return Infinity;
    }
  }

  public getMaxLocationsNumber(): number {
    const ds = this._dataset;
    const ch = ds.getConfigHelper();
    const trendsMaxVizelCount: number = ch.getIntValue('trends.maxVizelCount', 3);
    const dashboardsPanelLocations: string = ch.getStringValue('dashboards.panel.locations') || ch.getStringValue('dashboard.panel.locations') || 'single';
    const axesOrder = makeAxesOrderFromUrl(this._url.ao, this._url.loc);
    switch (this._url.route) {
      case '#dashboards':
        return dashboardsPanelLocations === 'multi' ? Infinity : 1;
      case '#plots':
      case '#trends':
        return axesOrder.zs === 'locations' ? trendsMaxVizelCount : Infinity;
      default:
        return Infinity;
    }
  }

  public _getPreset(dataset: IDatasetModel, url: IUrl): IPreset {
    if (!isObject(url)) {
      return null;
    }
    if (Array.isArray(url.metrics) && url.metrics.length) {          // individual parameters selected
      return null;
    }
    return getEntity(dataset.presets, String(url.preset));
  }

  public _getMetrics(dataset: IDatasetModel, url: IUrl): IMetric[] {
    const preset: IPreset = this._getPreset(dataset, url);
    let metrics: IMetric[] = [];

    if (preset) {
      metrics = preset.metrics.slice(0, this.getMaxParametersNumber());
    } else if (Array.isArray(this._url.metrics) && this._url.metrics.length) {
      metrics = $esid(dataset.metrics, this._url.metrics);
    }
    return metrics;
  }

  //
  // setters
  //
  private _setFormulaLocations(locations: ILocation[], skipCheck = false) {
    if (!skipCheck && locations.length > this.getMaxLocationsNumber()) {
      throw new DsStateService.MaxSelectedItemsError();
    }
    const ids = locations.map(l => l.id);
    urlState.navigate({locations: ids.length ? ids : null, geo: null});
  }

  public setFormulaLocations(locations: ILocation[], skipCheck = false) {
    this._setFormulaLocations(locations, skipCheck);
  }

  private _hasFormulaLocation(location: ILocation): boolean {
    return this._model.formulaLocations.indexOf(location) !== -1;
  }

  private _addFormulaLocation(location: ILocation): void {
    if (!this._hasFormulaLocation(location)) {
      this._setFormulaLocations(this._model.formulaLocations.concat(location));
    }
  }

  public removeFormulaLocation(location: ILocation): void {
    this._removeFormulaLocation(location);
  }

  public _removeFormulaLocation(location: ILocation): void {
    if (this._hasFormulaLocation(location)) {
      this._setFormulaLocations(this._model.formulaLocations.filter((l) => l !== location), true);
    }
  }

  public toggleFormulaLocation(location: ILocation): void {
    if (this._hasFormulaLocation(location)) {
      this._removeFormulaLocation(location);
    } else {
      this._addFormulaLocation(location);
    }
  }

  public setMetrics(metrics: IMetric[], skipCheck: boolean = false) {
    if (!skipCheck && metrics.length > this.getMaxParametersNumber()) {
      throw new DsStateService.MaxSelectedItemsError();
    }
    urlState.navigate({
      preset: null,
      metrics: metrics.map(m => m.id),
      parameters: null,
    });
  }

  private _hasMetric(metric: IMetric): boolean {
    return !this._getPreset(this._dataset, this._url) && this._getMetrics(this._dataset, this._url).indexOf(metric) !== -1;
  }

  private _addMetric(metric: IMetric): void {
    if (this._getPreset(this._dataset, this._url)) {
      this.setMetrics([metric]);
      return;
    }
    if (!this._hasMetric(metric)) {
      const ms: IMetric[] = this._getMetrics(this._dataset, this._url);
      const usedDimensions: number[] = uniq(ms.map((m: IMetric) => m.unit_id));
      const isNewDimension: boolean = (usedDimensions.indexOf(metric.unit_id) === -1);
      if (this._url.route === '#map' && isNewDimension) {
        this.setMetrics([metric]);                                              // when we are on maps and trying to select new unit
        return;                                                                 // we silently uncheck all and select only a new one
      }

      const axesOrder = makeAxesOrderFromUrl(this._url.ao, this._url.loc);
      if ((this._url.route === '#map' || axesOrder.ys === 'metrics') && usedDimensions.length >= 2 && isNewDimension) {
        throw new DsStateService.DimensionsError();
      }
      this.setMetrics(this._getMetrics(this._dataset, this._url).concat(metric));
    }
  }

  public removeMetric(metric: IMetric): void {
    if (this._hasMetric(metric)) {
      this.setMetrics(this._getMetrics(this._dataset, this._url).filter((p) => p !== metric), true);
    } else if (this._getPreset(this._dataset, this._url) && this._getPreset(this._dataset, this._url).metrics.indexOf(metric) != -1) {
      const ms: IMetric[] = this._getMetrics(this._dataset, this._url);                       // this._getPreset().metrics
      this.setMetrics(ms.filter((m: IMetric) => m !== metric), true);
    }
  }

  public toggleParameter(metric: IMetric): void {
    if (this._hasMetric(metric)) {
      this.removeMetric(metric);
    } else {
      this._addMetric(metric);
    }
  }

  public setGeo(newGeo: IGeo): void {
    const isGeoSet: boolean = !!(this._url.geo.lat && this._url.geo.lng && this._url.geo.zoom);
    urlState.navigate(
      {geo: newGeo},
      {replace: isGeoSet, trigger: true});
  }
  public setCustomConfig(config: any): void {
    this._updateModel({ customConfig: config });
  }

  public setPreset(preset: IPreset): void {
    urlState.navigate({preset: preset.preset_id, parameters: null, metrics: null});
  }

  public setDboard(db): void {
    urlState.navigate({dboard: db.id});
  }

  public setDash(dash): void {
    urlState.navigate({dash: dash ? dash.id : null});
  }

  public setChartType(chartType: string): void {
    urlState.updateModel({
      map: {
        vizelType: chartType,
      },
    });
  }

  public setMapfill(mapfill: IMapFill): void {
    if (!isObject(mapfill)) {
      urlState.navigate({mf: mapfill});
    } else {
      const mf: any = {};
      if ('enabled' in mapfill) mf.e = mapfill.enabled;
      if ('locations' in mapfill) mf.ls = Array.isArray(mapfill.locations) ? mapfill.locations.map(l => l.id) : null;
      if ('metric' in mapfill) mf.m = mapfill.metric ? mapfill.metric.id : null;
      urlState.navigate({mf});
    }
  }

  public setMapMetricsPanelVisible(mapMetricsPanelVisible: boolean): void {
    this._updateModel({ mapMetricsPanelVisible });
  }

  public isActive(): boolean {
    const url = UrlState.getInstance().getModel();
    const schema_name: string = url.segment === 'ds' ? String(url.segmentId) : '';
    return (
      String(this._dataset.id) === schema_name ||
      this._dataset.guid === schema_name ||
      this._dataset.schema_name === schema_name);
  }

  public getDataset(): IDatasetModel {
    return this._dataset;
  }

  public setPeriods(start: IPeriod, end: IPeriod, pt: number) {
    if (start || end) {
      urlState.navigate({period: {start: start ? start.id : null, end: end ? end.id : null, type: null}});
    } else if (pt != null) {
      urlState.navigate({period: {start: null, end: null, type: pt}});
    } else {
      throw new Error('State::setPeriod: not start, nor end, nor type provided');
    }
  }

  public setAxesOrder(axesOrder: IAxesOrder): void {
    urlState.navigate({ao: axesOrderStringify(axesOrder)});
  }

  public setAutoscale(value): void {
    urlState.navigate({autoscale: value});
  }

  public goToPlots(extra?: any): void {
    urlState.navigate($.extend({}, extra, {route: '#trends'}));
  }

  public static MaxSelectedItemsError = function () {
    //
  };

  public static DimensionsError = function () {
    //
  };

  protected _dispose(): void {
    delete DsStateService._cache[this._id];
    super._dispose();
  }

  private static _cache: {[id: string]: DsStateService} = {};

  public static createInstance(id: string | number): DsStateService {
    // DEBUG
    if (typeof window !== 'undefined') {
      (window as any).__dsStateServices = this._cache;
    }

    if (id in this._cache) {
      return this._cache[id].retain();
    }

    for (let key in this._cache) {
      if (this._cache.hasOwnProperty(key)) {
        const dsStateService: DsStateService = this._cache[key];
        const dsState: IDsState = dsStateService.getModel();
        const ds: IDatasetModel = dsState.dataset;
        if (ds && (ds.id === id || ds.guid === id || ds.schema_name === id)) {
          return dsStateService.retain();
        }
      }
    }

    const obj: DsStateService = new DsStateService(id);        // counter = 1, no retain needed
    this._cache[id] = obj;
    return obj;
  }
}
