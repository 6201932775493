import React from 'react';
import InternalComponentVC from '../../view-controllers/InternalComponentVC';
import useService from '../useService';
import { srv, UrlState } from '@luxms/bi-core';
import NullService from '../../services/NullService';
import ResourceLocatorService from '../../services/ResourceLocatorService';

interface ILoadFromResourcesProps {
  path: string;
  children?: any;
  [key: string]: any;
}

// interface ILoadFromResourcesState {
//   error: string;
//   loading: boolean;
//   schema_name: string;
//   path: string;
//   Component: any;
// }
//
// export class LoadFromResources extends React.Component<ILoadFromResourcesProps, ILoadFromResourcesState> {
//   public state: ILoadFromResourcesState = {
//     error: null,
//     loading: true,
//     schema_name: '',
//     path: '',
//     Component: null
//   };
//   private _dsResourceService: ResourceLocatorService = null;
//   private _dsResResourceService: ResourceLocatorService = null;
//   private _internalComponentVC: InternalComponentVC = null;
//
//   public constructor(props: any) {
//     super(props);
//   }
//
//   public componentDidMount() {
//     this._recreateResourcesServices(this.props.path);
//   }
//
//   public componentWillUnmount() {
//     this._disposeInternalComponentVC();
//     this._disposeResourcesServices();
//   }
//
//   public componentDidUpdate(prevProps: Readonly<ILoadFromResourcesProps>, prevState: Readonly<ILoadFromResourcesState>, snapshot?: any) {
//     if (this.props.path !== prevProps.path) {
//       this._recreateResourcesServices(this.props.path);
//     }
//     if (this.state.schema_name !== prevState.schema_name || this.state.path !== prevState.path) {
//       this._disposeInternalComponentVC();
//       if (this.state.schema_name && this.state.path) {
//         this._internalComponentVC = new InternalComponentVC(this.state.schema_name, this.state.path);
//         this._internalComponentVC.subscribeUpdatesAndNotify(this._onIntServiceUpd);
//       }
//     }
//   }
//
//   public static getDerivedStateFromError(error) {
//     console.error(error);
//     return { error: error.message };
//   }
//
//   private _disposeResourcesServices() {
//     if (this._dsResourceService) {
//       this._dsResourceService.unsubscribe(this._onResourcesUpdated);
//       this._dsResourceService.release();
//       this._dsResourceService = null;
//     }
//     if (this._dsResResourceService) {
//       this._dsResResourceService.unsubscribe(this._onResourcesUpdated);
//       this._dsResResourceService.release();
//       this._dsResResourceService = null;
//     }
//   }
//
//   private _recreateResourcesServices(path: string) {
//     this._disposeResourcesServices();
//     this._dsResourceService = new ResourceLocatorService(null, path);
//     this._dsResResourceService = new ResourceLocatorService('ds_res', path);
//     this._dsResourceService.subscribeUpdates(this._onResourcesUpdated);
//     this._dsResResourceService.subscribeUpdates(this._onResourcesUpdated);
//     this._onResourcesUpdated();
//   }
//
//   private _disposeInternalComponentVC() {
//     if (this._internalComponentVC) {
//       this._internalComponentVC.unsubscribe(this._onIntServiceUpd);
//       this._internalComponentVC.release();
//       this._internalComponentVC = null;
//     }
//   }
//
//   private _onResourcesUpdated = () => {
//     const dsResource = this._dsResourceService.getModel();
//     const dsResResource = this._dsResResourceService.getModel();
//
//     if (dsResource.error || dsResResource.error) {
//       this.setState({error: dsResource.error || dsResResource.error, loading: false, schema_name: '', path: '', Component: null});
//       return;
//     }
//
//     if (dsResource.loading || dsResResource.loading) {
//       this.setState({loading: true, schema_name: '', path: '', Component: null});
//       return;
//     }
//
//     let schema_name: string = null;
//
//     if (dsResource.resource) {
//       this.setState({schema_name: dsResource.schema_name, path: dsResource.resource.alt_id});
//     } else if (dsResResource.resource) {
//       this.setState({schema_name: dsResResource.schema_name, path: dsResResource.resource.alt_id});
//     } else {
//       this.setState({loading: false, error: null, schema_name: '', path: '', Component: null});
//     }
//   }
//
//   private _onIntServiceUpd = (module) => {
//     if (module.loading) {
//       this.setState({Component: null, error: null, loading: true});
//     }
//     if (module.error) {
//       this.setState({Component: null, error: module.error, loading: false});
//     }
//     this.setState({Component: module.Component, error: module.error, loading: false});
//   }
//
//   public render() {
//     const {children} = this.props;
//     const {error, loading, Component} = this.state;
//     if (error) {
//       return (<div style={{width: 'auto', height: 'auto'}} className="Vizel error">{error}</div>);
//     }
//     if (loading) return null;
//
//     let module = Component && !error ? <Component {...this.props}/> : children;
//     return module;
//   }
// }

const LoadFromResources = (props: ILoadFromResourcesProps) => {
  const dsResource = useService<ResourceLocatorService>(ResourceLocatorService, null, props.path);
  const dsResResource = useService<ResourceLocatorService>(ResourceLocatorService, 'ds_res', props.path);

  let schema_name: string = null;

  if (dsResource.resource) {
    schema_name = dsResource.schema_name;
  } else if (dsResResource) {
    schema_name = dsResResource.schema_name;
  }

  const internalComponent = schema_name ?
    useService<InternalComponentVC>(InternalComponentVC, schema_name, props.path) :
    useService<any>(NullService);

  const error = dsResResource.error || dsResResource.error || internalComponent.error;
  const loading = dsResResource.loading || dsResResource.loading || internalComponent.loading;

  // ошибки игнорим же?
  if (loading) return null;

  return internalComponent.Component ? React.createElement(internalComponent.Component, props) : props.children;
};

export default LoadFromResources;
