import { AuthenticationService, BaseService, createSingleton, IDisposable, UrlState, IBaseModel } from '@luxms/bi-core';
import { DsStateService } from '../services/ds/DsStateService';
import { IRawDashboard } from '@luxms/bi-core/dist/repositories/ds';

export interface IEditModeModel extends IBaseModel {
  readonly enabled: boolean;                                                                        // Возможен ли режим редактирования для текущего пользователя
  readonly editMode: boolean;
}

class EditModeVC extends BaseService<IEditModeModel> {
  public static MODEL: IEditModeModel;
  private readonly _currentDashBoard: CurrentDashBoard;

  protected constructor() {
    super({
      error: null,
      loading: true,
      enabled: false,
      editMode: false,
    });
    this._currentDashBoard = new CurrentDashBoard();

    AuthenticationService.subscribeUpdates(this._onSrvUpdated);
    UrlState.subscribeUpdates(this._onSrvUpdated);
    this._currentDashBoard.subscribeUpdatesAndNotify(this._onSrvUpdated); // сам должен определить есть ли у нас выбранный dashboard
    this._onSrvUpdated();
  }

  private _onSrvUpdated = async (): Promise<void> => {
    const auth = AuthenticationService.getModel();
    const url = UrlState.getModel();
    const currentDashBoard = this._currentDashBoard.getModel();

    const error = auth.error || url.error || currentDashBoard.error;
    const loading = auth.loading || url.loading || currentDashBoard.loading;
    if (error) return this._updateWithError(error);
    if (loading) return this._updateWithLoading();

    const dashboard = currentDashBoard.dashboard;

    let enabled = auth.access_level === 'admin' && (url.route === '#dashboards' || (url.segment === 'ds' && url.segmentId === null) || (url.segment === 'ds' && /@_/.test(url.segmentId)));
    if (auth.access_level !== 'admin' && dashboard && '_is_editable' in dashboard) enabled = !!(dashboard as any)._is_editable;


    this._updateWithData({enabled, editMode: enabled ? this._model.editMode : false});
  }

  public enterEditMode(): void {
    if (this._model.error) throw new Error(this._model.error);
    if (this._model.loading) throw new Error('loading');
    if (!this._model.enabled) throw new Error('Edit mode disabled');
    this._updateModel({editMode: true});
  }

  public exitEditMode(): void {
    this._updateModel({editMode: false});
  }

  public save(): void {
    this._notify('save');
    this.exitEditMode();
  }

  public reset(): void {
    this._notify('reset');
    this.exitEditMode();
  }

  public static enterEditMode() {
    return this.getInstance().enterEditMode();
  }

  public static exitEditMode() {
    return this.getInstance().exitEditMode();
  }

  public static save() {
    return this.getInstance().save();
  }

  public static reset() {
    return this.getInstance().reset();
  }

  public static getModel(): IEditModeModel {
    return this.getInstance().getModel();
  }

  public static subscribeUpdatesAndNotify(listener: (model: IEditModeModel) => void): IDisposable {
    return this.getInstance().subscribeUpdatesAndNotify(listener);
  }

  public static subscribe(event: string, listener: any): IDisposable {
    return this.getInstance().subscribe(event, listener);
  }

  public static unsubscribe(listener: (...args: any[]) => any): boolean {
    return this.getInstance().unsubscribe(listener);
  }

  protected _dispose() {
    AuthenticationService.unsubscribe(this._onSrvUpdated);
    UrlState.unsubscribe(this._onSrvUpdated);
    this._currentDashBoard.unsubscribe(this._onSrvUpdated);
    super._dispose();
  }

  public static getInstance = createSingleton(() => new EditModeVC(), '__editModeVC');
}

export default EditModeVC;

interface ICurrentDashBoardModel extends IBaseModel {
  readonly dashboard: IRawDashboard;
}

// сделал обертку чтобы if'ы не плодить в EditModeVC
class CurrentDashBoard extends BaseService<ICurrentDashBoardModel> {
  private _shemaName: string;

  public constructor() {
    super({
      loading: false,
      error: null,
      dashboard: null
    });

    UrlState.subscribeUpdatesAndNotify(this._onSrvUpdated);
  }

  // какая-то дичь которую нужно переписать
  private _onSrvUpdated = async () => {
    const urlModel = UrlState.getModel();
    if (urlModel.loading) return this._updateWithLoading();
    if (urlModel.error) return this._updateWithError(urlModel.error);

    const hasShema = urlModel.segmentId && !urlModel.segmentId.startsWith('@_');
    if (hasShema) return this._checkDashboard();
    else {
      this._shemaName = null;
      this._updateWithData({dashboard: null});
    }
  }

  private _checkDashboard = async (): Promise<any> => {
    const urlModel = UrlState.getModel();
    if (this._shemaName === urlModel.segmentId) return;
    this._shemaName = urlModel.segmentId;
    const dsStateService = DsStateService.createInstance(this._shemaName);
    await dsStateService.whenReady();
    const dashboard: any = dsStateService.getModel().dboard;
    this._updateWithData({dashboard});
  }

  protected _dispose() {
    UrlState.unsubscribe(this._onSrvUpdated);
    super._dispose();
  }
}